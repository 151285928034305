const lastWillLanding = {
  mainBanner: {
    intro: 'Crea un testamento online:',
    title: 'Legalmente valido, completato in 10 minuti',
    subtitle:
      'La definizione proattiva di ciò che accadrà al tuo patrimonio aiuta a garantire le tue ultime volontà e rende il processo più comodo per la tua famiglia.',
    buttonLabel: 'Crea testamento',
  },
  youtubeTutorial: 'Perché è importante un testamento?',
  aboutCards: {
    whoGetsWhat: {
      title: 'Chi ottiene cosa',
      description:
        'Il testamento stabilisce chi riceve quanto, permettendo di discostarsi dalla successione legale.',
    },
    enforceWishes: {
      title: 'Far valere i desideri',
      description:
        'Assicurati di distribuire il tuo patrimonio secondo i tuoi desideri e le tue intenzioni personali.',
    },
    clarificationForFamily: {
      title: 'Chiarezza per la famiglia',
      description:
        'Elimina discussioni inutili e fornisci chiarezza ai tuoi cari.',
    },
  },
  subBanner: {
    title: 'Ben regolato con un testamento conforme alla legge',
    subtitle:
      'Con gut geregelt, puoi creare rapidamente il tuo testamento, ricevere consulenza legale su temi delicati o domande aperte e fare un favore ai tuoi cari.',
    buttonLabel: 'Crea ora gratuitamente',
  },
  newsletter: {
    title: 'Diventa ben regolato con il tuo testamento',
    buttonLabel: 'Crea ora',
  },
  faq: {
    questions: {
      question1: 'Quali vantaggi offre un testamento?',
      question2: 'Esistono diversi modi per creare un testamento?',
      question3: 'Cosa rientra nel diritto ereditario legale?',
      question4:
        'Cosa è cambiato con la revisione del diritto successorio il 1° gennaio 2023?',
      question5:
        'Su quali parti del mio patrimonio posso disporre liberamente nel testamento?',
      question6: 'Cosa succede se non ho un testamento e muoio?',
      question7: 'Posso redigere il mio testamento insieme al mio coniuge?',
      question8:
        'Posso lasciare in eredità a persone al di fuori della mia famiglia?',
      question9: 'Posso diseredare qualcuno?',
      question10: 'Devo nominare un esecutore testamentario?',
      question11: 'Posso modificare il mio testamento in qualsiasi momento?',
      question12:
        'Ci sono altri documenti che dovrei creare oltre al testamento?',
      question13: 'Come mi aiuta gut geregelt con il mio testamento?',
    },
    answers: {
      answer1:
        'Con un testamento, regoli la tua successione. Decidi cosa accadrà al tuo patrimonio dopo la tua morte. Inoltre, se vivi in una convivenza e desideri che il tuo partner erediti, devi assolutamente indicarlo nel testamento.',
      answer2:
        'Sì, esistono il testamento olografo, il testamento pubblico e il testamento orale.',
      answer3:
        'Esiste una quota legittima, il diritto ereditario legale, che garantisce che il coniuge o i figli non possano essere completamente esclusi dalla tua eredità.',
      answer4:
        'Il cambiamento più grande è che la quota legittima dei genitori è completamente eliminata e la quota legittima dei figli per i testatori non sposati è ridotta dal 75% al 50%.',
      answer5:
        'Oltre alle quote legittime, puoi decidere liberamente sulla tua eredità. Puoi, ad esempio, favorire maggiormente il tuo coniuge o partner registrato.',
      answer6:
        'In questo caso, entra automaticamente in vigore la successione legale e la tua eredità viene divisa, a seconda della tua situazione familiare al momento della morte.',
      answer7:
        'No, non puoi redigere il tuo testamento insieme al tuo coniuge.',
      answer8:
        'Sì, oltre alla quota legittima stabilita dalla legge, puoi decidere autonomamente chi nominare come erede, anche associazioni o istituzioni.',
      answer9:
        'Finché non si tratta di eredi che hanno diritto alla quota legittima, puoi diseredare.',
      answer10:
        'No, la nomina di un esecutore testamentario è facoltativa, ma può proteggere da controversie ereditarie.',
      answer11:
        'Sì, puoi apportare modifiche in qualsiasi momento, è importante che le datti e le firmi.',
      answer12:
        'Sì, la {{powerOfAttorney}} e la {{livingWill}} sono due documenti che dovresti creare il prima possibile.',
      answer13:
        'Con gut geregelt ti aiutiamo a creare il tuo testamento in modo fluido e legalmente valido.',
    },
    hyperlinks: {
      lastWill: 'Testamento',
      livingWill: 'Direttiva del paziente',
      powerOfAttorney: 'Procura',
    },
  },
  situations: {
    heirat: {
      content: {
        main: {
          title: 'Testamento',
          description:
            'Con un testamento, stabilisci cosa succede al tuo patrimonio in caso di decesso. Puoi assicurarti che il tuo coniuge e la tua famiglia siano protetti e che la tua volontà sia rispettata.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'Un testamento ti dà la sicurezza che il tuo patrimonio sarà distribuito secondo i tuoi desideri e che il tuo coniuge sia legalmente protetto.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Protezione del coniuge',
              'Regole di successione chiare',
              'Prevenzione delle controversie',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Ulteriori informazioni',
        startProcess: 'Avvia il processo',
      }
    },
    'geburt-eines-kindes': {
      content: {
        main: {
          title: 'Testamento',
          description:
            'Con un testamento, stabilisci come viene diviso il tuo patrimonio in caso di decesso. Puoi assicurarti che tuo figlio sia finanziariamente sicuro e che i tuoi desideri siano rispettati.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'Un testamento ti dà la sicurezza che tuo figlio sia ben accudito in caso di decesso e che i tuoi beni siano distribuiti secondo i tuoi desideri.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Sicurezza finanziaria per il partner e la famiglia',
              'Chiarezza giuridica sulla successione',
              'Protezione del patrimonio familiare',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Ulteriori informazioni',
        startProcess: 'Avvia il processo',
      }
    },
    pensionierung: {
      content: {
        main: {
          title: 'Testamento',
          description:
            'Con un testamento, stabilisci come viene diviso il tuo patrimonio dopo la tua morte. Ti assicuri che i tuoi cari siano protetti secondo i tuoi desideri.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'In età pensionabile è importante occuparsi della pianificazione successoria per evitare controversie ereditarie e proteggere la tua famiglia.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Chiarezza sulla distribuzione del patrimonio',
              'Protezione della famiglia',
              'Prevenzione delle controversie',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Ulteriori informazioni',
        startProcess: 'Avvia il processo',
      }
    },
    krankheit: {
      content: {
        main: {
          title: 'Testamento',
          description:
            'Con un testamento, stabilisci come viene diviso il tuo patrimonio dopo la tua morte. In questo modo ti assicuri che i tuoi cari siano protetti e che la tua ultima volontà sia rispettata.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'Una diagnosi grave rende chiaro quanto velocemente la vita possa cambiare. Un testamento garantisce che la tua famiglia sia finanziariamente sicura e che le controversie siano evitate.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Protezione della tua famiglia',
              "Chiarezza sulla distribuzione dell'eredità",
              'Protezione del patrimonio',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Ulteriori informazioni',
        startProcess: 'Avvia il processo',
      }
    },
    unternehmensgruendung: {
      content: {
        main: {
          title: 'Testamento',
          description:
            'Con un testamento regoli cosa succede alla tua azienda e al tuo patrimonio in caso di decesso. In questo modo ti assicuri che la tua eredità sia gestita secondo i tuoi desideri e che la tua azienda continui.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              "Proprio come fondatore di un'azienda, è importante prendere precauzioni tempestivamente. Con un testamento, assicuri la continuazione della tua azienda ed eviti controversie legali.",
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              "Regole di successione chiare per l'azienda",
              'Prevenzione delle controversie',
              'Protezione del patrimonio familiare e del valore aziendale',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Ulteriori informazioni',
        startProcess: 'Avvia il processo',
      }
    },
    scheidung: {
      content: {
        main: {
          title: 'Testamento',
          description:
            'Con un testamento, stabilisci come viene diviso il tuo patrimonio in caso di decesso. Soprattutto dopo un divorzio, dovresti assicurarti che le tue regole di successione riflettano le tue nuove circostanze di vita.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'Un divorzio cambia le tue circostanze familiari e patrimoniali. Un testamento (aggiornato) garantisce che i tuoi desideri siano rispettati anche dopo la separazione.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Chiarezza sulla distribuzione del tuo patrimonio',
              'Protezione dei tuoi cari',
              'Adattamento alla situazione familiare modificata',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Ulteriori informazioni',
        startProcess: 'Avvia il processo',
      }
    },
  },
};

export default lastWillLanding;
