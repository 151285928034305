import queryClient from '#/api/query-client';
import { useDelete, useGet, usePost, usePut } from '#/api/reactQuery';
import {
  IAdminDashboardStats,
  IAdvisor,
  IAdvisorAPI,
  IAdvisorDefault,
  IChartTable,
  ICreateUser,
  IPartnerSettings,
  ITeamAPI,
  ITeamInvite,
  IUnlockWidget,
  IUsersList,
  IWidgetsList,
} from '#/types/partner';

const baseURL = '/partners';

export const PartnerEndpoints = {
  partnerSettings: () => `${baseURL}/settings/`,
  teamList: () => `${baseURL}/team/`,
  inviteTeamMember: () => `${baseURL}/invite-team/`,
  resendInvitation: () => `${baseURL}/resend-invitation/`,
  deleteTeamMember: () => `${baseURL}/delete-team-member/`,
  consultantsList: () => `${baseURL}/consultant/list/`,
  consultantByTag: () => `${baseURL}/consultants/by-tag/`,
  advisorsList: () => `${baseURL}/advisors/`,
  updateAdvisorName: () => `${baseURL}/advisors/update/name/`,
  endClientAdvisorsList: () => `${baseURL}/advisors/user/`,
  advisorDefaultSetting: () => `${baseURL}/advisors/default/`,
  deleteAdvisor: (advisorID: number | string) =>
    `${baseURL}/advisors/${advisorID}/delete/`,
  editAdvisor: () => `${baseURL}/advisors/edit/`,
  adminDashboardStats: () => `${baseURL}/dashboard/`,
  adminChartStats: () => `${baseURL}/dashboard/stats`,
  cancelInvitation: () => `${baseURL}/cancel-invitation/`,
  consultantUserList: () => `${baseURL}/members/`,
  deleteUser: () => `${baseURL}/delete-user/`,
  widgetList: () => `${baseURL}/unlock-widget/`,
  unlockWidget: () => `${baseURL}/unlock-widget/`,
  createUser: () => `${baseURL}/create-user/`,
  cancelUserInvitation: () => `${baseURL}/cancel-user-invitation/`,
  resendUserInvitation: () => `${baseURL}/resend-user-invitation/`,
  publicPartnerSettings: () => `${baseURL}/get-partner-settings/`,
  pageVisit: (partnerName: string) => `${baseURL}/${partnerName}/page-visit/`,
  disconnectFromPartner: () => `${baseURL}/disconnect-partner/`,
  assignPartner: () => `${baseURL}/assign-partner/`,
  partnersList: () => `${baseURL}/list/`,
  requestConsultation: () => `${baseURL}/advisors/advisor-request/`,
  transferUsers: () => `${baseURL}/transfer/users/`,
  hireTaxExpert: () => `${baseURL}/hire-tax-consultant/`,
  submitLatestTax: () => `${baseURL}/tax-consultant/submit/`,
};

export const invalidatePartnerQueries = {
  // partnerSettings: () => {
  //   queryClient
  //     .invalidateQueries(PartnerEndpoints.partnerSettings())
  //     .then((r) => r);
  // },
  teamList: () => {
    queryClient.invalidateQueries(PartnerEndpoints.teamList()).then((r) => r);
  },
  advisorsList: () => {
    queryClient
      .invalidateQueries(PartnerEndpoints.advisorsList())
      .then((r) => r);
  },
  adminDashboardStats: () => {
    queryClient
      .invalidateQueries(PartnerEndpoints.adminDashboardStats())
      .then((r) => r);
  },
  consultantUserList: () => {
    queryClient
      .invalidateQueries(PartnerEndpoints.consultantUserList())
      .then((r) => r);
  },
  widgetList: () => {
    queryClient.invalidateQueries(PartnerEndpoints.widgetList()).then((r) => r);
  },
};

export const useGetPartnerSettings = (enabled: boolean) =>
  useGet<IPartnerSettings>(
    PartnerEndpoints.partnerSettings(),
    {},
    {
      enabled,
    }
  );

export const useGetPublicPartnerSettings = (
  subdomain: string | undefined | null,
  enabled: boolean
) =>
  useGet<IPartnerSettings>(
    PartnerEndpoints.publicPartnerSettings(),
    {
      subdomain,
    },
    {
      enabled,
    }
  );

export const useUpdatePartnerSettings = () =>
  usePut<IPartnerSettings, IPartnerSettings>(
    PartnerEndpoints.partnerSettings()
  );

export const useGetTeamList = (
  page: number,
  enabled: boolean,
  roles?: string,
  search?: string | undefined,
  sort_by?: string | undefined
) =>
  useGet<ITeamAPI>(
    PartnerEndpoints.teamList(),
    {
      page,
      roles,
      search,
      sort_by,
    },
    {
      enabled,
    }
  );

export const useInviteTeamMember = () =>
  usePost<ITeamInvite, ITeamInvite>(PartnerEndpoints.inviteTeamMember());

export const useGetAdminDashboardStats = (enabled: boolean) =>
  useGet<IAdminDashboardStats>(
    PartnerEndpoints.adminDashboardStats(),
    {},
    {
      enabled,
      refetchOnWindowFocus: 'always',
    }
  );

export const useGetChartTableData = (enabled: boolean) =>
  useGet<IChartTable>(
    PartnerEndpoints.adminChartStats(),
    {},
    {
      enabled,
      refetchOnWindowFocus: 'always',
    }
  );

export const useGetAdvisorsList = (
  page: number,
  advisor_type: string,
  enabled: boolean,
  location?: string,
  search?: string | undefined,
  sort_by?: string | undefined
) =>
  useGet<IAdvisorAPI>(
    PartnerEndpoints.advisorsList(),
    {
      page,
      advisor_type,
      location,
      search,
      sort_by,
    },
    {
      enabled,
    }
  );

export const useGetEndClientAdvisorsList = (
  page: number,
  advisor_type: string,
  enabled: boolean,
  location?: string,
  search?: string | undefined,
  sort_by?: string | undefined
) =>
  useGet<IAdvisorAPI>(
    PartnerEndpoints.endClientAdvisorsList(),
    {
      page,
      advisor_type,
      location,
      search,
      sort_by,
    },
    {
      enabled,
    }
  );

export const useAddAdvisor = () =>
  usePost<IAdvisor, IAdvisor>(PartnerEndpoints.advisorsList());

export const useChangeAdvisorDefaultSetting = () =>
  usePost<IAdvisorDefault, IAdvisorDefault>(
    PartnerEndpoints.advisorDefaultSetting()
  );

export const useCancelInvitation = () =>
  usePost<{ id: string | number }, { id: string | number }>(
    PartnerEndpoints.cancelInvitation()
  );

export const useGetConsultantUserList = (
  page: number,
  enabled: boolean,
  search?: string | undefined,
  sort_by?: string | undefined,
  min_percentage?: number | null,
  max_percentage?: number | null,
  filter_field?: string | null
) =>
  useGet<IUsersList>(
    PartnerEndpoints.consultantUserList(),
    {
      page,
      search,
      sort_by,
      min_percentage,
      max_percentage,
      filter_field,
    },
    {
      refetchOnMount: 'always',
      enabled,
    }
  );

export const useResendInvitation = () =>
  usePost<
    { invitation_id: string | number },
    { invitation_id: string | number }
  >(PartnerEndpoints.resendInvitation());

export const useDeleteTeamMember = () =>
  usePost<{ id: string | number }, { id: string | number }>(
    PartnerEndpoints.deleteTeamMember()
  );

export const useDeleteUser = () =>
  usePost<{ user_id: string | number }, { user_id: string | number }>(
    PartnerEndpoints.deleteUser()
  );

export const useDeleteAdvisor = (advisorID: string | number) =>
  useDelete<{ id: string | number }>(PartnerEndpoints.deleteAdvisor(advisorID));

export const useEditAdvisor = () =>
  usePut<IAdvisor, IAdvisor>(PartnerEndpoints.editAdvisor());

export const useGetWidgetList = () =>
  useGet<IWidgetsList>(PartnerEndpoints.widgetList());

export const useUnlockWidget = () =>
  usePost<IUnlockWidget, IUnlockWidget>(PartnerEndpoints.unlockWidget());

export const useCreateUser = () =>
  usePost<ICreateUser, ICreateUser>(PartnerEndpoints.createUser());

export const useCancelUserInvitation = () =>
  usePost<{ id: string | number }, { id: string | number }>(
    PartnerEndpoints.cancelUserInvitation()
  );

export const useResendUserInvitation = () =>
  usePost<{ id: string | number }, { id: string | number }>(
    PartnerEndpoints.resendUserInvitation()
  );

export const useSendPageVisit = (partnerName: string) =>
  usePost<{ partner_name: string }, { partner_name: string }>(
    PartnerEndpoints.pageVisit(partnerName)
  );

export const useDisconnectFromPartner = () =>
  usePost(PartnerEndpoints.disconnectFromPartner());

export const useAssignPartner = () =>
  usePost<{ partner_id: string | number }, { partner_id: string | number }>(
    PartnerEndpoints.assignPartner()
  );

type Partner = {
  id: number;
  partner_name: string;
};

export const useGetPartnersList = () =>
  useGet<Partner[]>(PartnerEndpoints.partnersList());

export const useRequestConsultation = () =>
  usePost(PartnerEndpoints.requestConsultation());

export const useUpdateAdvisorName = () =>
  usePost<
    { first_name: string | number; last_name: string },
    { first_name: string | number; last_name: string }
  >(PartnerEndpoints.updateAdvisorName());

export const useGetConsultantsList = () =>
  useGet<any[]>(PartnerEndpoints.consultantsList());

export const useTransferUsers = () =>
  usePost<
    {
      ids: string[] | number[];
      destination_consultant: string | number | null;
    },
    { ids: string[] | number[]; destination_consultant: string | number | null }
  >(PartnerEndpoints.transferUsers());

export interface IConsultantByTag {
  id: number;
  user_id: number;
  email: string;
  first_name: string;
  last_name: string;
  partner_name: string;
  role: string;
  tag: string;
  created_at: string;
  last_login: string | null;
}

export const useGetConsultantByTag = (tag?: string, enabled?: boolean) =>
  useGet<IConsultantByTag[]>(
    PartnerEndpoints.consultantByTag(),
    { tag },
    {
      enabled,
    }
  );

export const useHireTaxExpert = () =>
  usePost<
    { consultant_id: string | number },
    { consultant_id: string | number }
  >(PartnerEndpoints.hireTaxExpert());

export const useSubmitLatestTax = () =>
  usePost(PartnerEndpoints.submitLatestTax());
