import {
  contractParties,
  lifeSituation,
} from '#/locales/en/cohabitationAgreement/questions';
import outputDoc from '#/locales/en/cohabitationAgreement/outputDoc';

const cohabitationAgreement = {
  mainHeader: {
    regulateTogether: 'Organize your life together',
    effectiveDocs: 'Your effective cohabitation agreement and inventory',
  },
  stepperInfo: [
    {
      title: 'Partner',
      label: 'Contract parties',
    },
    {
      title: 'Living situation',
      label: 'Living situation',
    },
    {
      title: 'Inventory',
      label: 'Inventory',
    },
    {
      title: 'Store your cohabitation agreement and inventory',
      label: 'Your agreement',
    },
  ],

  stepOne: {
    questions: contractParties,
    malePartner: 'Your partner (male)',
    femalePartner: 'Your partner (female)',
  },
  stepTwo: {
    questions: lifeSituation,
    periodOptions: {
      monthly: 'Monthly',
      bi_monthly: 'Every two months',
      quarterly: 'Quarterly',
      weekly: 'Weekly',
      bi_weekly: 'Every two weeks',
    },
    jointQuestions: {
      joint_household_account_bank:
        'At which bank do you hold your joint household account?',
      equal_payment:
        'What is the amount of equal payments, and how often are they made?',
      compensation_payment_cadence:
        'How often do you check the account balance for potential compensation payments?',
      expenses_to_be_covered: 'Which expenses will be covered?',
    },
    oneFullTimeOnePartTime: {
      who_works_full_time: 'Who works full-time?',
      your_partner: '{{partnersGender}}',
      you: 'You',
      yourWorkload: 'What is your workload?',
      partnersWorkload: 'What is the workload of {{partnersGender}}?',
      malePartner: 'your partner (male)',
      femalePartner: 'your partner (female)',
      equal_payment:
        'What are the amounts, the share per party, and the frequency of payments?',
      account_balance_in_case_of_separation:
        'How should the account balance be distributed in case of separation?',
    },
    oneFullTimeOtherNotWorking: {
      household_and_children_responsibility:
        'Who is mainly responsible for the household and children?',
      monthly_transfer_for_free_disposal:
        'Do you make a monthly transfer to your partner for free disposal / Do you receive a monthly transfer for free disposal from your partner?',
      yes: 'Yes',
      no: 'No',
      free_disposal_amount: 'How much?',
    },
  },
  stepThree: {
    soleOwnershipList:
      'Please list all items that are solely owned by you or your partner.',
    commonlyPurchasedList: 'Please list all items you have purchased together.',
    confirmDeleteInventory: {
      title: 'Confirm deletion',
      description: 'Are you sure you want to delete this item?',
      cancel: 'Cancel',
      delete: 'Delete',
    },
  },
  stepFour: {
    document: outputDoc,
    nextStepsTitle: 'Next steps',
    nextStep1:
      'Print the cohabitation agreement and inventory and read them carefully.',
    nextStep2: 'Sign both documents.',
    nextStep3: 'Upload both files below.',
    nextStep4: 'Regularly update the inventory.',
  },
  expensesOptions: {
    rent: 'Rent',
    additional:
      'Additional housing costs such as electricity, heating, water, waste disposal fees, etc.',
    radioTV: 'Radio and TV Connection',
    groceries: 'Groceries',
    cleaning: 'Cleaning expenses',
  },
};

export default cohabitationAgreement;
