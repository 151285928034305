const outputDoc = {
  title: 'Last will',
  from: 'from',
  born: ' born on ',
  civilStatus: {
    single: 'Single',
    married: 'Married',
    widowed: 'Widowed',
    divorced: 'Divorced',
    registered_partnership: 'Registered partnership',
  },
  civil_status: 'Civil status: {{civil_status}}',
  address: 'Address: {{address}}',
  declare: 'I, {{first_name}} {{last_name}}, declare the following:  ',

  paragraph1: 'I revoke all wills I have previously made.',

  paragraph2:
    'The ongoing liabilities and death costs are to be paid from the estate.',

  paragraph3: '{{civil_status}}',

  receivesNothing: 'receives nothing.',

  and: 'and',

  p4MarriedSpouse: {
    receives: 'receives',
    marriedStatus: {
      male: 'My wife',
      female: 'My husband',
    },
    livingPartnershipStatus: {
      male: 'My registered partner (female)',
      female: 'My registered partner (male)',
    },
    partnershipContract: {
      yes: 'legally all community property and inheritance all assets within the freely available quota',
      no: 'legally the entire proposal and inheritance all assets within the freely available quota',
    },
    quota: 'Quota',
    noContractButChildren: {
      main: 'only for usufruct however the compulsory portion that falls on {{children}}',
      oneChild: 'the child',
      multipleChildren: 'the children',
      receive: ' falls.',
    },
    propertyContractAndChildren: {
      main: 'only for usufruct however the compulsory portion that falls on {{children}}',
      oneChild: 'the child receives',
      multipleChildren: 'the children.',
    },
    born: ', born on {{birthday}}, ',
  },

  p4UnmarriedPartner: {
    receives: 'receives all assets within the freely available quota.',
    receivesProperty: 'also receives in particular',
    theProperty: 'the property',
    atTheAddress: 'at the address',
    assignedToProperty: 'assigned to ownership.',
    born: ', born on {{birthday}}, ',
    genderStatus: {
      male: 'My partner (female)',
      female: 'My partner (male)',
      male1: 'my partner (female)',
      female1: 'my partner (male)',
      registeredPartnerMale: 'My partner (female)',
      registeredPartnerFemale: 'My partner (male)',
    },
  },

  p5UnmarriedPartnerWithChildren: {
    commonPartnership: {
      oneChild: {
        main: 'Our common child, ',
        born: ', born on {{birthday}}, ',
        receive: 'or their descendants receive the compulsory portion.',
      },
      multipleChildren: {
        main: 'Our common children, ',
        born: ', born on {{birthday}}, ',
        receive:
          'or all their descendants each receive the compulsory portion.',
      },
      and: 'and',
    },
    otherPartnership: {
      oneChild: {
        main: 'My non-common child, ',
        born: ', born on {{birthday}}, ',
        receive: 'or their descendants receive the compulsory portion.',
      },
      multipleChildren: {
        main: 'My non-common children, ',
        born: ', born on {{birthday}}, ',
        receive:
          'or all their descendants each receive an equal share of the compulsory portion.',
      },
    },
    noPartnership: {
      oneChild: {
        main: 'My child, ',
        born: ', born on {{birthday}}, ',
        receive: ' or their descendants receive the compulsory portion.',
      },
      multipleChildren: {
        main: 'My children, ',
        born: ', born on {{birthday}}, ',
        receive:
          ' or all their descendants each receive the compulsory portion.',
      },
    },
    deathCase:
      'Should I die simultaneously with {{partner}}, then {{childrenCase}}',
    born: ', born on {{birthday}}, ',
    childrenCase: {
      oneChild: {
        fromCommonMarriage: 'our common child receives, ',
        fromOtherMarriage: 'our non-common child receives, ',
        fromUnknownMarriage: 'my child receives,',
      },
      onesDescendants: 'or their descendants all assets.',
      multipleChildren: {
        fromCommonMarriage: 'our common children receive, ',
        fromOtherMarriage: 'our non-common children receive, ',
        fromUnknownMarriage: 'my children receive, ',
      },
      allDescendants: 'or all their descendants each receive all assets.',
    },
  },

  p4Child: {
    commonMarriage: {
      oneChild: {
        main: 'Our common child, ',
        born: ', born on {{birthday}}, ',
        receive:
          'or all their descendants each receive an equal share of all assets within the freely available quota. ',
      },
      multipleChildren: {
        main: 'Our common children, ',
        born: ', born on {{birthday}}, ',
        receive:
          'or all their descendants each receive an equal share of all assets within the freely available quota. ',
      },
    },
    otherMarriage: {
      oneChild: {
        main: 'My non-common child, ',
        born: ', born on {{birthday}}, ',
        receive:
          'or all their descendants each receive an equal share of all assets within the freely available quota. ',
      },
      multipleChildren: {
        main: 'My non-common children, ',
        born: ', born on {{birthday}}, ',
        receive:
          'or all their descendants each receive an equal share of all assets within the freely available quota. ',
      },
      and: 'and',
    },
    partnerCase: {
      male: 'My wife',
      female: 'My husband',
      born: ', born on {{birthday}}, ',
      receivesShare: 'receives the compulsory portion.',
    },
  },

  unmarriedChildrenChoice: {
    commonPartnership: {
      oneChild: {
        main: 'Our common child, ',
        born: ', born on {{birthday}}, ',
        receives:
          'or all their descendants each receive an equal share of all assets',
      },
      multipleChildren: {
        main: 'Our common children, ',
        born: ', born on {{birthday}}, ',
        receives:
          'or all their descendants each receive an equal share of all assets',
      },
      quota: 'within the freely available quota.',
      and: 'and',
    },
    otherPartnership: {
      oneChild: {
        main: 'My non-common child, ',
        born: ', born on {{birthday}}, ',
        receives:
          'or all their descendants each receive an equal share of all assets',
      },
      multipleChildren: {
        main: 'My non-common children, ',
        born: ', born on {{birthday}}, ',
        receives:
          'or all their descendants each receive an equal share of all assets',
      },
      quota: 'within the freely available quota.',
      and: 'and',
    },
    noPartnership: {
      oneChild: {
        main: 'My child, ',
        born: ', born on {{birthday}}, ',
        receives: 'or their descendants receive',
      },
      multipleChildren: {
        main: 'My children, ',
        born: ', born on {{birthday}}, ',
        receives: 'or all their descendants each receive',
      },
      quota: 'an equal share of all assets within the freely available quota.',
      and: 'and',
    },
  },

  unmarriedInstitutionChoice: {
    commonPartnership: {
      oneChild: {
        main: 'Our common child, ',
        born: ', born on {{birthday}}, ',
        receive: 'or their descendants receive the compulsory portion.',
      },
      multipleChildren: {
        main: 'Our common children, ',
        born: ', born on {{birthday}}, ',
        receive:
          'or all their descendants each receive the compulsory portion.',
      },
      and: 'and',
    },
    otherPartnership: {
      oneChild: {
        main: 'My non-common child, ',
        born: ', born on {{birthday}}, ',
        receive: 'or their descendants receive the compulsory portion.',
      },
      multipleChildren: {
        main: 'My non-common children, ',
        born: ', born on {{birthday}}, ',
        receive:
          'or all their descendants each receive the compulsory portion.',
      },
      and: 'and',
    },
    noPartnership: {
      oneChild: {
        main: 'My child, ',
        born: ', born on {{birthday}}, ',
        receive: 'or their descendants receive the compulsory portion.',
      },
      multipleChildren: {
        main: 'My children, ',
        born: ', born on {{birthday}}, ',
        receive:
          'or all their descendants each receive the compulsory portion.',
      },
      and: 'and',
    },
  },

  p4Institution: {
    oneInstitution: {
      main: 'The institution',
      receives: 'receives',
    },
    multipleInstitutions: {
      main: 'The institutions',
      receives: 'receive',
    },
    finals:
      ' each receive an equal share of all assets within the freely available quota. ',
    final: ' all assets within the freely available quota. ',
    partnerReceives: 'receives the compulsory portion.',
    born: ', born on {{birthday}}, ',
    and: ' and ',
  },

  p5MarriedSpouse: {
    marriedStatus: {
      male: 'My wife',
      female: 'My husband',
    },
    livingPartnershipStatus: {
      male: 'My registered partner (female)',
      female: 'My registered partner (male)',
    },
    particularReceives: 'also receives in particular',
    theProperty: 'the property',
    atTheAddress: 'at the address',
    assignedToProperty: 'assigned to ownership.',
  },

  p5Institution: {
    commonMarriage: {
      oneChild: {
        main: 'Our common child, ',
        born: ' born on {{birthday}}, ',
        receive: 'or their descendants receive the compulsory portion.',
      },
      multipleChildren: {
        main: 'Our common children, ',
        born: ' born on {{birthday}}, ',
        receive:
          'or all their descendants each receive the compulsory portion.',
      },
      and: 'and',
    },
    otherMarriage: {
      oneChild: {
        main: 'My non-common child, ',
        born: ' born on {{birthday}}, ',
        receive: 'or their descendants receive the compulsory portion.',
      },
      multipleChildren: {
        main: 'My non-common children, ',
        born: ' born on {{birthday}}, ',
        receive:
          'or all their descendants each receive the compulsory portion.',
      },
      and: 'and',
    },
  },

  p6MarriedSpouse: {
    oneChild: {
      commonMarriage: 'Our common child, ',
      otherMarriage: 'My non-common child, ',
      born: ', born on {{birthday}}, ',
      receives: 'or their descendants receive ',
    },
    multipleChildren: {
      commonMarriage: 'Our common children, ',
      otherMarriage: 'My non-common children, ',
      born: ', born on {{birthday}}, ',
      receives: 'or all their descendants each receive',
    },
    final:
      'the compulsory portion, however burdened with the usufruct mentioned in the first paragraph. ',
  },

  willExecutor:
    'As executor for my entire estate, I appoint {{executor1}}. As substitute executor, I appoint {{executor2}}.',

  forFollowing: {
    main: 'However, the following assets are subject to a different regulation:',
    asset: '{{asset}}, {{address}} goes to: {{benefiter}}. ',
    devisionProvision:
      'This is a division provision in favor of {{benefiter}}, not an additional benefit.',
  },
};

export default outputDoc;
