const registerAssets = {
  mainBanner: {
    title: 'Clarity and overview with the register of assets',
    subtitle:
      'A comprehensive documented overview of all your assets not only gives you peace of mind but also makes life easier for your family members.',
    buttonLabel: 'Gain clarity',
  },
  youtubeTutorial: 'How to prepare your estate?',
  aboutCards: {
    logPhysicalAssets: {
      title: 'Log physical assets',
      description:
        'Log physical assets where your loved ones can find them and to whom you want to bequeath them.',
    },
    outlineRelationships: {
      title: 'Outline relationships',
      description:
        'Note your bank details and other places where you have invested assets to make it easier for your family to locate.',
    },
    simplifyCleanup: {
      title: 'Simplify the cleanup',
      description:
        'Store everything centrally and well protected in one place so that trusted persons can quickly access relevant information.',
    },
  },
  subBanner: {
    title: 'The big picture that saves your family dozens of hours',
    subtitle:
      "Many of our customers know this from experience – when little is documented and recorded, painful hours are wasted trying to get a clear overview of the deceased's property and assets. We help your loved ones avoid this experience.",
    buttonLabel: 'Get overview',
  },
  testimonials: {
    testimonial1: {
      name: 'Elisabeth Müller',
      text: "My daughter helped me log my husband's and my assets into the register of assets. It was a relief for all of us to have an overview and know where we have what.",
    },
    testimonial2: {
      name: 'Sascha Zürcher',
      text: 'I already had a will. But with gut-geregelt, I now also have an overview of all my assets that I can update regularly and make directly accessible to the relevant people in the event of my death.',
    },
    testimonial3: {
      name: 'Vreni Frey',
      text: "A simple solution that is a good exercise to write down everything of value. I was surprised by how much I had accumulated that I hadn't directly thought of.",
    },
  },
  newsletter: {
    title: 'Your estate, well-regulated',
    buttonLabel: 'Organize now',
  },
  faq: {
    questions: {
      question1: 'What is a register of assets and why is it important?',
      question2:
        'What types of assets can be included in the register of assets?',
      question3: 'How does the register of assets service work?',
      question4:
        'How can I ensure that my loved ones have access to my register of assets?',
      question5: 'What happens to my assets after my death?',
      question6: 'Can I update or change my register of assets?',
      question7: 'How secure is my personal information?',
      question8:
        'What legal aspects should be considered when managing a register of assets?',
      question9: 'Can the service help heirs settle financial matters?',
    },
    answers: {
      answer1:
        'A register of assets is a comprehensive record of all physical assets and relationships to financial institutions of a person. It is important to keep such a register to have a clear overview of your assets and to ease the process for your loved ones after death. This can save them dozens of hours.',
      answer2:
        'The register can include a variety of assets, including real estate, vehicles, jewelry, artwork, bank accounts, investment accounts, and more.',
      answer3:
        'Our service provides a user-friendly platform where customers can capture and manage their assets. They can define the location, ownership status, and foreseen beneficiaries for each asset.',
      answer4:
        "You define trusted individuals who will have unrestricted access to your account after your death. They only receive access with a death certificate and an executor's certificate.",
      answer5:
        'The register of assets, together with the will, helps ease the process for your loved ones by providing clear instructions on how the assets should be distributed or managed and where to find them.',
      answer6:
        'Yes, customers can update or change their register of assets at any time to reflect changes in their assets or beneficiaries.',
      answer7:
        'We implement strict security measures to ensure the highest confidentiality and security of customer information, including industry-standard encryption and access controls.',
      answer8:
        'Customers should consider legal matters such as creating a will and inheritance issues. It is advisable to seek legal advice to ensure that the register complies with applicable laws.',
      answer9:
        "Yes, by providing a clear overview of the deceased's assets and making the identification and management of assets easier, the service helps heirs clarify financial matters.",
    },
  },

  situations: {
    pensionierung: {
      content: {
        main: {
          title: 'Register of assets',
          description:
            'A register of assets documents your possessions and valuables. This creates transparency and ensures that everything that is important to you remains in good hands.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'After death, the division of assets and possessions often becomes a burden. With an asset register, you prevent ambiguities and ensure that everything is settled according to your wishes.',
            ],
          },
          {
            title: 'Your benefits',
            description: [
              'Clarity about assets and possessions',
              'Protection against disputes',
              'Order and transparency for your family',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Learn more',
        startProcess: 'Start process',
      }
    },

    'tod-verwandter': {
      content: {
        main: {
          title: 'Register of assets',
          description:
            'A register of assets and properties documents your possessions and valuables. This creates transparency and ensures that everything that is important to you remains in good hands.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'After the death of a relative, the division of assets and possessions often becomes a burden. With an asset register, you prevent ambiguities and ensure that everything is settled according to your wishes.',
            ],
          },
          {
            title: 'Your benefits',
            description: [
              'Clarity about assets and possessions',
              'Protection against disputes',
              'Order and transparency for your family',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Learn more',
        startProcess: 'Start process',
      }
    },
  },
};

export default registerAssets;
