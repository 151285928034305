import { IQuestion } from '#/types/globalTypes';

export const inheritorsMarriedVersion: IQuestion[] = [
  {
    question:
      'You have indicated that you are married. Could you please provide us with the details of your spouse?',
  },
  {
    question:
      'Do you have a marriage contract or a real estate contract?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'Do you have a property/apartment that you would like to bequeath to your partner?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  // {
  //   question: 'Is there a divorce proceeding pending for you?',
  //   options: [
  //     {
  //       label: 'Yes',
  //     },
  //     {
  //       label: 'No',
  //     },
  //   ],
  // },
  {
    question: 'Do you have children from a previous or current marriage?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  // {
  //   question: 'Would you like to benefit other people or institutions?',
  //   options: [
  //     {
  //       label: 'Yes',
  //     },
  //     {
  //       label: 'No',
  //     },
  //   ],
  // },
];

export const inheritorsSingleVersion: IQuestion[] = [
  {
    question: 'Are you currently in a committed relationship?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'Have you concluded a real estate contract (registered partnership)?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'Do you have a property/apartment that you would like to bequeath to your partner?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question: 'Do you have children?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  // {
  //   question: 'Would you like to benefit other people or institutions?',
  //   options: [
  //     {
  //       label: 'Yes',
  //     },
  //
  //     {
  //       label: 'No',
  //     },
  //   ],
  // },
];

export const inheritorsDivorcedVersion: IQuestion[] = [
  {
    question: 'Are you currently in a committed relationship?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'You have indicated that you are divorced. Do you have children?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  // {
  //   question: 'Would you like to benefit other people or institutions?',
  //   options: [
  //     {
  //       label: 'Yes',
  //     },
  //     {
  //       label: 'No',
  //     },
  //   ],
  // },
];

export const distributionQuestions: IQuestion[] = [
  {
    question:
      'As far as legally permissible, I would like as much of my estate as possible to be distributed to:',
    options: [
      {
        label: 'Spouse/registered partner/life partner',
      },
      {
        label: 'Children',
      },
      {
        label: 'An institution of my choice',
      },
    ],
  },
  {
    question:
      'Would you like to give specific items or a specific sum of money to a family member, a specific person, even outside your family, or an institution?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  // {
  //   question:
  //     'Do you have various inheritances that you would like to give to different people?',
  //   options: [
  //     {
  //       label: 'Yes',
  //     },
  //     {
  //       label: 'No',
  //     },
  //   ],
  // },
];

export const governanceQuestions: IQuestion[] = [
  {
    question: 'Who should be the executor of the last will?',
  },
];