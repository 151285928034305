import {
  contractParties,
  lifeSituation,
} from '#/locales/fr/cohabitationAgreement/questions';
import outputDoc from '#/locales/fr/cohabitationAgreement/outputDoc';

const cohabitationAgreement = {
  mainHeader: {
    regulateTogether: 'Régulez votre vie commune',
    effectiveDocs: 'Votre contrat de cohabitation et inventaire effectif',
  },
  stepperInfo: [
    {
      title: 'Partenaire',
      label: 'Parties contractantes',
    },
    {
      title: 'Situation de vie',
      label: 'Situation de vie',
    },
    {
      title: 'Inventaire',
      label: 'Inventaire',
    },
    {
      title: 'Déposez votre contrat de cohabitation et votre inventaire',
      label: 'Votre contrat',
    },
  ],

  stepOne: {
    questions: contractParties,
    malePartner: 'Votre partenaire',
    femalePartner: 'Votre partenaire',
  },
  stepTwo: {
    questions: lifeSituation,
    periodOptions: {
      monthly: 'Mensuel',
      bi_monthly: 'Deux mois',
      quarterly: 'Trimestriel',
      weekly: 'Hebdomadaire',
      bi_weekly: 'Deux semaines',
    },
    jointQuestions: {
      joint_household_account_bank:
        'Dans quelle banque avez-vous votre compte commun de ménage ?',
      equal_payment:
        'Quel est le montant des paiements égaux et à quelle fréquence sont-ils effectués ?',
      compensation_payment_cadence:
        "À quelle fréquence vérifiez-vous le solde du compte pour d'éventuels paiements de compensation ?",
      expenses_to_be_covered: 'Quels frais seront couverts ?',
    },
    oneFullTimeOnePartTime: {
      who_works_full_time: 'Qui travaille à plein temps ?',
      your_partner: '{{partnersGender}}',
      you: 'Vous',
      yourWorkload: 'Quelle est votre charge de travail ?',
      partnersWorkload:
        'Quelle est la charge de travail par rapport à {{partnersGender}}',
      malePartner: 'Votre partenaire',
      femalePartner: 'Votre partenaire',
      equal_payment:
        'Quels sont les montants, la répartition par partie et la fréquence des paiements ?',
      account_balance_in_case_of_separation:
        'Comment le solde du compte doit-il être réparti en cas de dissolution de la communauté de vie ?',
    },
    oneFullTimeOtherNotWorking: {
      household_and_children_responsibility:
        "Qui s'occupe du ménage et des enfants ?",
      monthly_transfer_for_free_disposal:
        'Transférez-vous à votre partenaire un montant mensuel à disposition libre/Recevez-vous de votre partenaire un transfert mensuel à disposition libre ?',
      yes: 'Oui',
      no: 'Non',
      free_disposal_amount: 'Combien ?',
    },
  },
  stepThree: {
    soleOwnershipList:
      'Veuillez lister tous les objets qui sont en votre propriété exclusive ou celle de votre partenaire.',
    commonlyPurchasedList:
      'Veuillez lister tous les articles que vous avez achetés ensemble',
    confirmDeleteInventory: {
      title: 'Confirmez la suppression',
      description: 'Voulez-vous vraiment supprimer cet article ?',
      cancel: 'Annuler',
      delete: 'Supprimer',
    },
  },
  stepFour: {
    document: outputDoc,
    nextStepsTitle: 'Les prochaines étapes',
    nextStep1:
      "Imprimez le contrat de cohabitation et l'inventaire et lisez-les attentivement.",
    nextStep2: 'Signez les deux documents.',
    nextStep3: 'Téléchargez les deux fichiers ci-dessous.',
    nextStep4: "Mettez régulièrement à jour l'inventaire",
  },
  expensesOptions: {
    rent: 'Loyer',
    additional:
      'Charges annexes comme électricité, chauffage, eau, frais de déchets, etc.',
    radioTV: 'Connexion radio et TV',
    groceries: 'Alimentation',
    cleaning: 'Frais de nettoyage',
  },
};

export default cohabitationAgreement;
