const digitalFootprintLanding = {
  mainBanner: {
    title: 'Organize and secure your digital legacy in a safe vault',
    subtitle:
      'Ensure all your electronic data or assets, devices, and insurance policies are secured and accessible to authorized persons in the event of your passing.',
    buttonLabel: 'Secure legacy',
  },
  youtubeTutorial: 'How to preserve your digital legacy?',
  aboutCards: {
    documentAccounts: {
      title: 'Document your accounts',
      description:
        'Easily transfer all accounts from your password manager into the "gut geregelt" platform. No worries. We specifically don’t ask for any passwords!',
    },
    connectDigitalValues: {
      title: 'Map digital assets',
      description:
        'List all your digital wallets, domains, and other assets, and describe where and how your family can find passwords to gain access.',
    },
    preserveDigitalData: {
      title: 'Preserve digital data',
      description:
        'Organize and store data like photos, videos, music, important documents, and other information in a secure vault.',
    },
  },
  subBanner: {
    title: 'Ensure you leave everything organized and tidy',
    subtitle:
      'Unless explicitly stated in your last will or testament, your digital assets will pass to your legal heirs upon death. It is therefore advisable to decide during your lifetime what should be deleted or transferred after your passing.',
    buttonLabel: 'Manage digital footprint',
  },
  testimonials: {
    testimonial1: {
      name: 'Patrick Zesiger',
      text: 'The digital legacy service from "gut geregelt" has helped me keep my digital affairs in perfect order. An absolute must for anyone engaged in the digital world!',
    },
    testimonial2: {
      name: 'Sara Becker',
      text: 'Knowing my family won’t need to sort things out and that all my social media accounts can be closed gives me peace of mind. Super easy to set up. Highly recommend.',
    },
    testimonial3: {
      name: 'Luisa Stauffer',
      text: 'I recently set up the digital legacy with and for my parents. It was quick and easy. Even my father was glad we finally got it sorted out.',
    },
  },
  newsletter: {
    title: 'Get well-organized for your digital legacy',
    buttonLabel: 'Organize now',
  },
  faq: {
    questions: {
      question1: 'What is a digital legacy and why is it important?',
      question2:
        'What types of digital assets can be included in a digital legacy?',
      question3: 'How does the digital legacy service work?',
      question4:
        'How can I ensure my relatives have access to my digital legacy?',
      question5: 'What happens to my digital assets when I die?',
      question6: 'How secure is my personal information with your service?',
      question7: 'Can I update or change my digital legacy?',
      question8:
        'What legal aspects should be considered when managing a digital legacy?',
      question9:
        'What happens to my online subscriptions and accounts after my death?',
      question10:
        'How does your service support the management of insurance and banking documents?',
      question11: 'Does "gut geregelt" store passwords or other access data?',
    },
    answers: {
      answer1:
        'A digital legacy encompasses all digital assets and online-stored information belonging to a person. This can range from social media accounts to online subscriptions and cryptocurrency wallets. Managing a digital legacy is important to ensure your digital affairs are handled properly after your passing, providing your relatives access to essential information.',
      answer2:
        'Our service allows you to document a wide range of digital assets, including social media accounts, online subscriptions, crypto wallets, domains, password managers, electronic devices, and more. You can document all relevant digital assets and leave instructions on what should happen to them after your passing and how to access them.',
      answer3:
        'Our service offers a user-friendly platform where you can document, manage, and specify instructions for handling your digital assets after your death. You can specify who should have access to which information and define what should happen to your digital assets.',
      answer4:
        'With our service, you can designate specific persons who will have access to your digital legacy. You can also leave detailed instructions on how your digital assets should be managed, ensuring your loved ones have access to crucial information.',
      answer5:
        'After your passing, the persons you designated will gain access to your "gut geregelt" account by presenting a death certificate and the executor’s authorization. This ensures they can manage your digital assets according to your wishes, whether certain accounts are deleted, maintained, or transferred. Our service ensures your digital matters are handled according to your instructions.',
      answer6:
        'We take the security of your personal information very seriously. Our service uses industry-standard security measures, including encryption and access restrictions, to protect your data. Additionally, we do not store sensitive information such as passwords, account balances, or similar data.',
      answer7:
        'Yes, you can update or change your digital legacy at any time. Our platform allows you to add new assets, delete or edit existing information, and leave new instructions.',
      answer8:
        'When managing a digital legacy, legal aspects like the creation of a will and the appointment of heirs should be considered. It’s advisable to seek legal advice to ensure your digital matters are handled in accordance with the applicable laws.',
      answer9:
        'Upon your passing, your online subscriptions and accounts can be managed according to your instructions. You can specify whether certain subscriptions are canceled, accounts deleted, or transferred to specific individuals.',
      answer10:
        'Our service provides tools for organizing your insurance and banking documents. You can indicate where specific documents are located. We also support the automatic generation of cancellation letters for insurance policies upon the submission of a death certificate and executor’s authorization.',
      answer11:
        'No, we explicitly do not store passwords or other access data and provide assistance to ensure you, as a customer, do NOT store passwords in "gut geregelt." Instead, we encourage you to keep account access information separately in a secure location accessible to your family. You can document this secure location in "gut geregelt" for your loved ones.',
    },
  },
  situations: {
    'tod-verwandter': {
      content: {
        main: {
          title: 'Digital footprint',
          description:
            'The digital legacy regulates what happens to your online accounts, social networks, and digital data after your death. You decide what should be deleted, transferred, or continued.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              "In today's world, we leave behind a multitude of digital traces. By regulating your digital legacy, you prevent sensitive data from remaining uncontrolled on the web and relieve your relatives.",
            ],
          },
          {
            title: 'Your benefits',
            description: [
              'Control over your digital data',
              'Protection against identity theft',
              'Relief for your relatives',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Learn more',
        startProcess: 'Start process',
      }
    },

    pensionierung: {
      content: {
        main: {
          title: 'Digital footprint',
          description:
            'Your digital life leaves traces - from online accounts to social media. With a regulation for your digital legacy, you determine what happens to your data after your death.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'In the digital age, it is important to regulate the digital legacy in order to secure the handling of sensitive data.',
            ],
          },
          {
            title: 'Your benefits',
            description: [
              'Protection of your digital identity',
              'Avoidance of misuse',
              'Relief for your relatives',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Learn more',
        startProcess: 'Start process',
      }
    },

    unternehmensgruendung: {
      content: {
        main: {
          title: 'Digital footprint',
          description:
            'The digital legacy regulates what happens to your online accounts, social networks, and digital data after your death. You decide what should be deleted, transferred, or continued.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              "In today's world, we leave behind a multitude of digital traces. By regulating your digital legacy, you prevent sensitive data from remaining uncontrolled on the web and relieve your relatives.",
            ],
          },
          {
            title: 'Your benefits',
            description: [
              'Control over your digital data',
              'Protection against identity theft',
              'Relief for your relatives',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Learn more',
        startProcess: 'Start process',
      }
    },
  },
};

export default digitalFootprintLanding;
