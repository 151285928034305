const provisionLanding = {
  mainBanner: {
    intro: 'Enfin bien préparer :',
    title:
      'Créer une directive anticipée, une procuration et un testament en ligne',
    subtitle:
      "En cas d'urgence, vos proches sont soumis à de nombreuses émotions. Épargnez-leur encore plus de stress dû à des formalités non résolues.",
    buttonLabel: "S'inscrire maintenant",
  },
  sale: '-43% / par personne',
  addMore: 'Ajouter {{membersNo}} de plus',
  youtubeTutorial: 'Pourquoi la prévoyance est-elle si importante ?',
  aboutCards: {
    remainInControl: {
      title: 'Rester en contrôle',
      description:
        "Si vous ne défendez pas vos propres intérêts, d'autres le feront — pas nécessairement dans votre meilleur intérêt.",
    },
    protectYourFamily: {
      title: 'Protéger votre famille',
      description:
        "Agissez de manière proactive pour clarifier comment votre famille gérera la situation si vous devenez incapable d'agir ou n'êtes plus là.",
    },
    relieveRelatives: {
      title: 'Soulager les proches',
      description:
        "Pensez à ceux qui auront beaucoup à faire si vous n'êtes plus en mesure de le faire. Assurez un soulagement.",
    },
  },
  whyWithUs: {
    title: 'Bonnes raisons de choisir gut geregelt ?',
    cards: {
      card1: {
        title: 'Compréhensible',
        description:
          'Vous pose-t-on des questions sur des choses que vous ne comprenez pas du tout ?! Pas chez nous. Nous parlons votre langue.',
      },
      card2: {
        title: 'Rapide à réaliser',
        description:
          'Au lieu de répondre à des dizaines de questions dans un processus long, vous obtenez ce que vous voulez en 10 minutes - garanti.',
      },
      card3: {
        title: 'Abordable',
        description:
          'gut geregelt vous permet de prendre des dispositions complètes et conformes à la loi à un prix imbattable.',
      },
    },
  },
  testimonials: {
    testimonial1: {
      name: 'Elisabeth Müller',
      text: 'Mon mari et moi discutons de ce sujet depuis des années. Grâce à notre fille, nous avons découvert gut geregelt. Nous avons été étonnés de voir à quel point il était facile et rapide de faire ce que nous avions repoussé pendant des années.',
    },
    testimonial2: {
      name: 'Sascha Zürcher',
      text: "Mon père était un homme d'affaires à l'ancienne. Très occupé, peu documenté. Lorsqu'il est décédé, ma sœur et moi avons dû péniblement créer de la clarté et de l'ordre. Bien que je ne sois pas encore âgé, je ne veux prendre aucun risque et épargner à ma famille un fardeau similaire. gut geregelt m'a permis de faire exactement cela. Et cela depuis le canapé.",
    },
    testimonial3: {
      name: 'Vreni Frey',
      text: "Il y a 2 ans, j'ai créé un testament avec mon avocat. Grâce à gut geregelt, j'ai maintenant aussi une directive anticipée et une procuration. Le processus était clair et surtout le langage compréhensible. Bien que j'espère ne jamais en avoir besoin, je suis contente de m'être décidée à créer ces documents.",
    },
  },
  subBanner: {
    title: 'Prévoyance conforme à la loi, simplifiée',
    subtitle:
      "gut geregelt offre un accès à des conseils juridiques si nécessaire et vous aide à créer des documents conformes à la loi, à les conserver en toute sécurité et à les partager en cas d'urgence avec ceux qui en ont besoin.",
    buttonLabel: 'Commencer maintenant',
  },
  pricing: {
    title: 'Le bon forfait pour vous',
    subtitle:
      'Nous avons quelque chose pour tout le monde. Choisissez maintenant le forfait qui correspond le mieux à vos besoins.',
    oneTime: 'unique',
    perYear: 'an',
    additionalMember: 'membre supplémentaire',
    purchaseButton: "Sélectionner maintenant",
    manageButton: 'Gérer',
    starter: {
      title: 'Starter',
      finfinderSale: 'Économisez 200 CHF avec FinFinder',
      features: [
        'Tous les documents de prévoyance',
        'Gestion numérique des documents',
        'Registre des biens et des actifs',
        'Héritage numérique',
        'Dossier fiscal',
        "Aide à l'organisation",
        'Accès invité pour les représentants',
        'Accès aux conseils juridiques et financiers',
      ],
    },
    standard: {
      title: 'Standard',
      finfinderSale: 'Économisez 400 CHF avec FinFinder',
      features: [
        'Tous les documents de prévoyance',
        'Gestion numérique des documents',
        'Registre des biens et des actifs',
        'Héritage numérique',
        'Dossier fiscal',
        "Aide à l'organisation",
        'Accès invité pour les représentants',
        'Rappels (documents à compléter, mises à jour juridiques et autres changements)',
        'Accès aux conseils juridiques et financiers',
      ],
    },
  },
  provisionBanner: {
    title: "Même si c'est difficile... Il est important d'y penser maintenant",
    subtitle:
      "Beaucoup d'entre nous ont tendance à reporter la prévoyance vieillesse. Après tout, personne n'aime penser au pire scénario possible. Cependant, il est important de se rappeler que vous ne rendez service ni à vos proches ni à vous-même si vous négligez cela. Plus vous commencez tôt, mieux c'est. Nous vous aidons à trouver le bon plan de prévoyance pour vous.",
    buttonLabel: 'Prévoir maintenant',
  },
  newsletter: {
    title: 'Devenez bien organisé',
    buttonLabel: 'Créez vos documents',
  },

  eightReasons: {
    title: '8 raisons de choisir gut geregelt',
    cards: {
      card1: {
        title: 'Simple et compréhensible',
        description: {
          provisionLanding:
            'Nous parlons votre langue et rendons les termes complexes facilement compréhensibles.',
          patientenverfuegung:
            'Nous parlons votre langue et rendons les termes complexes facilement compréhensibles.',
          vorsorgeauftrag:
            'Nous parlons votre langue et rendons les termes complexes facilement compréhensibles.',
          testament:
            'Nous parlons votre langue et rendons les termes complexes facilement compréhensibles.',
          ehevertrag:
            'Nous parlons votre langue et rendons les termes complexes facilement compréhensibles.',
          'digitaler-nachlass':
            'Nous parlons votre langue et rendons les termes complexes facilement compréhensibles.',
          konkubinatsvertrag:
            'Nous parlons votre langue et rendons les termes complexes facilement compréhensibles.',
        },
      },
      card2: {
        title: 'Réalisé en 30 minutes',
        description: {
          provisionLanding:
            'Votre document de prévoyance finalisé en seulement 14 minutes en moyenne grâce à notre processus optimisé.',
          patientenverfuegung:
            'Votre directive anticipée finalisée en seulement 14 minutes en moyenne grâce à notre processus optimisé.',
          vorsorgeauftrag:
            'Votre procuration finalisée en seulement 14 minutes en moyenne grâce à notre processus optimisé.',
          testament:
            'Votre testament finalisé en seulement 14 minutes en moyenne grâce à notre processus optimisé.',
          ehevertrag:
            'Votre contrat de mariage finalisé en seulement 14 minutes en moyenne grâce à notre processus optimisé.',
          'digitaler-nachlass':
            'Votre patrimoine numérique finalisé en seulement 14 minutes en moyenne grâce à notre processus optimisé.',
          konkubinatsvertrag:
            'Votre contrat de concubinage finalisé en seulement 14 minutes en moyenne grâce à notre processus optimisé.',
        },
      },
      card3: {
        title: 'Confortablement depuis chez vous',
        description: {
          provisionLanding:
            'Créez vos documents confortablement depuis chez vous, sans rendez-vous, quand cela vous convient.',
          patientenverfuegung:
            'Créez votre directive anticipée confortablement depuis chez vous, sans rendez-vous, quand cela vous convient.',
          vorsorgeauftrag:
            'Créez votre procuration confortablement depuis chez vous, sans rendez-vous, quand cela vous convient.',
          testament:
            'Créez votre testament confortablement depuis chez vous, sans rendez-vous, quand cela vous convient.',
          ehevertrag:
            'Créez votre contrat de mariage confortablement depuis chez vous, sans rendez-vous, quand cela vous convient.',
          'digitaler-nachlass':
            'Créez votre patrimoine numérique confortablement depuis chez vous, sans rendez-vous, quand cela vous convient.',
          konkubinatsvertrag:
            'Créez votre contrat de concubinage confortablement depuis chez vous, sans rendez-vous, quand cela vous convient.',
        },
      },
      card4: {
        title: 'Modifiable à tout moment',
        description: {
          provisionLanding:
            'Adaptez vos documents à tout moment facilement et sans complication à de nouvelles circonstances de vie.',
          patientenverfuegung:
            'Adaptez votre directive anticipée à tout moment facilement et sans complication à de nouvelles circonstances de vie.',
          vorsorgeauftrag:
            'Adaptez votre procuration à tout moment facilement et sans complication à de nouvelles circonstances de vie.',
          testament:
            'Adaptez votre testament à tout moment facilement et sans complication à de nouvelles circonstances de vie.',
          ehevertrag:
            'Adaptez votre contrat de mariage à tout moment facilement et sans complication à de nouvelles circonstances de vie.',
          'digitaler-nachlass':
            'Adaptez votre patrimoine numérique à tout moment facilement et sans complication à de nouvelles circonstances de vie.',
          konkubinatsvertrag:
            'Adaptez votre contrat de concubinage à tout moment facilement et sans complication à de nouvelles circonstances de vie.',
        },
      },
      card5: {
        title: 'Légalement sûr',
        description: {
          provisionLanding:
            'Créez des documents juridiquement sûrs, vérifiés par des experts et toujours à jour.',
          patientenverfuegung:
            'Créez une directive anticipée juridiquement sûre, vérifiée par des experts et toujours à jour.',
          vorsorgeauftrag:
            'Créez une procuration juridiquement sûre, vérifiée par des experts et toujours à jour.',
          testament:
            'Créez un testament juridiquement sûr, vérifié par des experts et toujours à jour.',
          ehevertrag:
            'Créez un contrat de mariage juridiquement sûr, vérifié par des experts et toujours à jour.',
          'digitaler-nachlass':
            'Créez un patrimoine numérique juridiquement sûr, vérifié par des experts et toujours à jour.',
          konkubinatsvertrag:
            'Créez un contrat de concubinage juridiquement sûr, vérifié par des experts et toujours à jour.',
        },
      },
      card6: {
        title: 'Conservation sécurisée',
        description: {
          provisionLanding: 'Conservation sécurisée de tous les documents.',
          patientenverfuegung:
            'Conservation sécurisée de la directive anticipée.',
          vorsorgeauftrag: 'Conservation sécurisée de la procuration.',
          testament: 'Conservation sécurisée du testament.',
          ehevertrag: 'Conservation sécurisée du contrat de mariage.',
          'digitaler-nachlass':
            'Conservation sécurisée du patrimoine numérique.',
          konkubinatsvertrag:
            'Conservation sécurisée du contrat de concubinage.',
        },
      },
      card7: {
        title: 'Accès pour les personnes importantes',
        description: {
          provisionLanding:
            'Notification et accès aux documents pertinents pour les proches, médecins ou exécuteurs testamentaires au bon moment.',
          patientenverfuegung:
            'Notification et accès à la directive anticipée pertinente pour les proches, médecins ou exécuteurs testamentaires au bon moment.',
          vorsorgeauftrag:
            'Notification et accès à la procuration pertinente pour les proches, médecins ou exécuteurs testamentaires au bon moment.',
          testament:
            'Notification et accès au testament pertinent pour les proches, médecins ou exécuteurs testamentaires au bon moment.',
          ehevertrag:
            'Notification et accès au contrat de mariage pertinent pour les proches, médecins ou exécuteurs testamentaires au bon moment.',
          'digitaler-nachlass':
            'Notification et accès au patrimoine numérique pertinent pour les proches, médecins ou exécuteurs testamentaires au bon moment.',
          konkubinatsvertrag:
            'Notification et accès au contrat de concubinage pertinent pour les proches, médecins ou exécuteurs testamentaires au bon moment.',
        },
      },
      card8: {
        title: 'Abordable',
        description: {
          provisionLanding:
            "Prévoyance complète et conforme à la loi à une fraction du coût d'un notaire.",
          patientenverfuegung:
            "Directive anticipée complète et conforme à la loi à une fraction du coût d'un notaire.",
          vorsorgeauftrag:
            "Procuration complète et conforme à la loi à une fraction du coût d'un notaire.",
          testament:
            "Testament complet et conforme à la loi à une fraction du coût d'un notaire.",
          ehevertrag:
            "Contrat de mariage complet et conforme à la loi à une fraction du coût d'un notaire.",
          'digitaler-nachlass':
            "Patrimoine numérique complet et conforme à la loi à une fraction du coût d'un notaire.",
          konkubinatsvertrag:
            "Contrat de concubinage complet et conforme à la loi à une fraction du coût d'un notaire.",
        },
      },
    },
  },
  betterRegulatedLife: {
    title: 'Une vie bien organisée',
    provisionCheck: {
      title: 'Contrôle de prévoyance',
      subtitle:
        'Nous comprenons votre situation de vie. Découvrez en deux minutes ce dont vous avez besoin pour une prévoyance bien organisée. Nous vous rencontrons là où vous en êtes.',
      buttonLabel: 'Commencer le contrôle de prévoyance',
    },
    lifeSituationCheck: {
      title: 'Contrôle de la situation de vie',
      subtitle:
        "Un nouveau chapitre de vie s'ouvre-t-il pour vous ou avez-vous vécu un événement marquant et vous vous demandez comment cela affecte votre prévoyance ?",
      buttonLabel: 'Commencer le contrôle de la situation de vie',
    },
  },
};

export default provisionLanding;
