const pensionProvision = {
  title: 'gut geregelt: Ti aiutiamo a proteggere i tuoi cari e te stesso',
  alreadyHaveAcc: 'Hai già un account?',
  signIn: 'Accedi',
  p1: 'Il team di gut geregelt è a tua disposizione per affrontare temi importanti che tutti dovrebbero considerare. Iniziamo con tre temi fondamentali per la pianificazione della pensione e della previdenza e amplieremo continuamente la nostra offerta per soddisfare le tue esigenze.',
  p2: 'Ti accompagniamo come un personal trainer nei processi importanti che devi affrontare, in modo da evitare problemi in situazioni difficili sia per te che per i tuoi cari.',
  p3: 'Discutiamo i dettagli. Raccomandiamo che tutti abbiano completato e finalizzato i seguenti documenti legali entro i 50 anni:',
  p3a: 'Se inizi ora e dedichi 30 minuti, ti garantiamo che sarai ben organizzato.',
  p4: "In base alle tue risposte, c'è un basso rischio che, come la maggior parte dei nostri clienti, tu non riesca a compiere i passi necessari al primo tentativo per organizzare bene la tua vita.",
  p4a: 'La maggior parte dei nostri clienti non riesce a compiere i passi necessari al primo tentativo per organizzare bene la propria vita.',
  p5a: 'Per supportarti in questo, offriamo un servizio di promemoria.',
  p5b: 'Per questo, abbiamo bisogno di alcuni dati da te ora, in modo da poterti ricordare i passi aperti.',
  p6a: 'Per questo motivo, desideriamo offrirti il nostro servizio di promemoria come supporto.',
  p6b: 'Per favore, fornisci ora un canale di comunicazione attraverso il quale possiamo ricordarti i passi aperti.',

  onboarding: {
    stepOne: {
      title: 'Controllo della previdenza',
      subtitle: 'Innanzitutto, vorremmo conoscerti meglio',
      questions: [
        {
          question: 'Prima di tutto: per chi vuoi pianificare la previdenza?',
          options: [
            {
              label: 'Me stesso',
            },
            {
              label: 'Membro della famiglia',
            },
          ],
        },
        {
          question: '{{referringTo}}',
          options: [
            {
              label: 'Sì',
            },
            {
              label: 'No',
            },
          ],
        },
      ],
      theirRealEstate: 'Il tuo familiare possiede immobili?',
      myRealEstate: 'Possiedi immobili?',
    },
    welcomeCard: {
      title: 'Benvenuto in gut geregelt',
      successMessage: 'Il tuo account è stato creato con successo',
      emailMessage: "Un'email con il tuo link di accesso è in arrivo",
      nextStep: 'Passo successivo',
      stepOne: "Apri il link nell'email",
      stepTwo: 'Crea la tua password personale',
      stepThree: 'Inizia con la tua pianificazione previdenziale',
    },
    stepTwo: {
      title: 'Controllo della previdenza',
      description: 'Cosa hanno le persone come te che hanno pianificato bene.',
      subtitlePersonal:
        'Grazie, {{genderFormal}} {{first_name}} {{last_name}}! Consigliamo a persone come te di assicurarsi di avere o considerare i seguenti artefatti o servizi.',
      femaleGenderBased: 'cara',
      maleGenderBased: 'caro',
      subtitleOther:
        'Grazie! Per persone come {{first_name}} {{last_name}} consigliamo di assicurarsi che considerino i seguenti artefatti o servizi (basati sul genere)',
      selectOnlyCompleted: 'Clicca su quelli che hai già.',
      seeResults: 'Mostra risultati',
      noCompletedService: 'Non ho completato nessuna delle opzioni',
    },
    stepThree: {
      title: 'Come possiamo aiutarti?',
      stepByStep: 'Passo dopo passo verso una previdenza meglio organizzata.',
      redLightLabel: 'Hai ancora molta strada da fare.',
      yellowLightLabel: 'Hai del lavoro da fare!',
      greenLightLabel: 'Sei ben organizzato',
      redLightRecommendation: {
        title:
          'Ma non preoccuparti, se fai un passo alla volta, arriverai rapidamente alla meta. Prendici in parola. Inizia semplicemente oggi con il primo passo.',
      },
      yellowLightRecommendation: {
        title:
          'Bene, ti sei già occupato di {{list}}. Ti consigliamo quindi di continuare con le seguenti cose:',
      },
      greenLightRecommendation: {
        title:
          "Wow! Hai già fatto tutto ciò che persone come te avrebbero dovuto fare. Dai un'occhiata anche ad altri argomenti che potrebbero offrirti un valore aggiunto.",
      },
      subtitle:
        "L'esperienza mostra che le questioni previdenziali sono spesso innescate da eventi della vita come matrimonio, figli o età.",
      provisionTopics: 'Temi di previdenza',
      provisionTopicsOptions: {
        financialAdvise: 'Consulente finanziario',
        legalAdvise: 'Consulenza legale',
      },
      lifeSituations: 'Situazioni di vita',
      lifeSituationsOptions: {
        marriage: 'Matrimonio',
        birthOfChild: 'Nascita di un figlio',
        purchaseOfRealEstate: 'Acquisto di un immobile',
        enteringRetirement: 'Entrata in pensione',
        illnes: 'Malattia',
        deathOfARelative: 'Morte di un parente',
        foundingACompany: "Fondazione di un'azienda",
        divorce: 'Divorzio',
      },
    },
  },

  confirmCheckbox:
    'Selezionando questa casella, riconosco e confermo di accettare di essere registrato su gut geregelt.',
  processingAgreement: 'Vedi il nostro accordo di elaborazione degli ordini',

  c1: {
    title: 'Direttiva Anticipata',
    description:
      'ti consente di stabilire come i professionisti medici dovrebbero procedere in caso di emergenza.',
  },
  c2: {
    title: 'Procura',
    description:
      'evita problemi con il KESB e regola la tutela dei tuoi figli.',
  },
  c3: {
    title: 'Testamento',
    description: 'per lasciare il tuo lascito come desideri.',
  },

  questions: [
    {
      question:
        'Se hai la scelta di fare qualcosa oggi o rimandarlo a domani... Cosa fai?',
      options: [
        {
          label: 'Lo faccio subito.',
        },
        {
          label: 'Non lo so.',
        },
        {
          label: 'Lo rimando a domani.',
        },
      ],
    },
    {
      question:
        'Se qualcuno cerca di motivarti a fare qualcosa che non ti piace... Cosa funziona meglio?',
      options: [
        {
          label: 'Gli incentivi mi mettono in movimento.',
        },
        {
          label: 'Non lo so.',
        },
        {
          label: 'Mi fa sentire male.',
        },
      ],
    },
    {
      question: 'Come affronti normalmente le cose?',
      options: [
        {
          label: 'Chiudo gli occhi e lo faccio.',
        },
        {
          label: 'Non lo so.',
        },
        {
          label: 'Più tardi.',
        },
      ],
    },
    {
      question:
        'Dicci quanto spesso desideri essere ricordato quando metti in pausa temporaneamente il processo:',
      options: [
        {
          label: 'Settimanalmente',
        },
        {
          label: 'Mensilmente',
        },
        {
          label: 'Trimestralmente',
        },
      ],
    },
  ],
  thankyouCard: {
    title: 'Previdenza semplice e senza complicazioni',
    thankYou: 'Grazie per il tuo interesse in gut geregelt',
    gentlyReminder:
      'Ti ricorderemo gentilmente fino a quando non avrai tutto ben organizzato.',
  },

  lifesituationCheck: {
    title: 'Controllo della situazione di vita',
    description:
      'Ogni fase della vita ha i suoi temi unici. Scopri cosa è rilevante per la fase in cui ti trovi.',
  },
};

export default pensionProvision;
