import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link, BoxProps } from '@mui/material';
import useResponsive from '#/hooks/useResponsive';
import useAuth from '#/hooks/useAuth';
import {
  useGetPartnerSettings,
  useGetPublicPartnerSettings,
} from '#/api/partnerQueries';
import Iconify from '#/components/shared/ui/Iconify';
import useLocalStorage from '#/hooks/useLocalStorage';

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, sx }) => {
    const isDesktop = useResponsive('up', 'md');
    const { isAuthenticated, user } = useAuth();
    const [ggPartnerKey] = useLocalStorage('gg_partner_key', null);

    const isAdminLoggedIn =
      user?.partner?.role === 'admin' || user?.partner?.role === 'owner';

    const { data: byAdmin } = useGetPartnerSettings(isAdminLoggedIn);
    const { data: partnerTheme, isLoading: gettingSettings } =
      useGetPublicPartnerSettings(ggPartnerKey, !!ggPartnerKey);

    const partnerLogo = byAdmin?.image || partnerTheme?.image;

    const defaultRoute = isAuthenticated ? '/dashboard/home' : '/';

    if (gettingSettings) {
      return (
        <Iconify
          icon="eos-icons:three-dots-loading"
          color="primary.main"
          width={!isDesktop ? 250 : 150}
          height={isDesktop ? 50 : 40}
        />
      );
    }

    const logo = (
      <Box
        component="img"
        src={partnerLogo || '/assets/logos/gutgeregeltLogo.svg'}
        sx={{
          // width: isDesktop ? 250 : 150,
          height: isDesktop ? 50 : 30,
          cursor: 'pointer',
          objectFit: 'contain',
          ...sx,
        }}
        alt="gut geregelt"
      />
    );

    if (disabledLink) {
      return logo;
    }

    return (
      <Link
        component={RouterLink}
        to={defaultRoute}
        sx={{ display: 'contents' }}
      >
        {logo}
      </Link>
    );
  }
);

export default Logo;
