import { useGet, usePost, usePut } from '#/api/reactQuery';
import { Invitation, Successor } from '#/types/user';
import queryClient from '#/api/query-client';
import { User } from '#/types/auth';

const baseURL = '/successor';

export const SuccessorEndpoints = {
  getInvitationsList: () => `${baseURL}/list-invitation/`,
  createInvitation: () => `${baseURL}/create-invitation/`,
  updateInvitation: (id: number | string | undefined) =>
    `${baseURL}/update-invitation/${id}/`,
  deleteInvitation: (id: number | string | undefined) =>
    `${baseURL}/remove-invitation/${id}/`,
  acceptInvitation: () => `${baseURL}/accept-invitation/`,
  listMyConnections: () => `${baseURL}/list-my-successor-connections/`,
  switchAsGuest: () => `${baseURL}/switch-account/`,
  activateHostAccount: () => `${baseURL}/activate-main-account/`,
  reportDeath: () => `${baseURL}/report-death/`,
};

export const invalidateSuccessorQueries = {
  getInvitationsList: () => {
    queryClient
      .invalidateQueries(SuccessorEndpoints.getInvitationsList())
      .then((r) => r);
  },
};

export const useGetInvitationsList = (type?: string, sharing?: string) =>
  useGet<Invitation['invitations']>(SuccessorEndpoints.getInvitationsList(), {
    type,
    sharing,
  });
export const useCreateInvitation = () =>
  usePost<Invitation, Invitation>(SuccessorEndpoints.createInvitation());

export const useUpdateInvitation = (id: number | string | undefined) =>
  usePut<Successor, Successor>(SuccessorEndpoints.updateInvitation(id));

export const useDeleteInvitation = (id: number | string | undefined) =>
  usePost<Successor['unique_id'], Successor['unique_id']>(
    SuccessorEndpoints.deleteInvitation(id)
  );

export interface AccountInfo extends User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  profile_picture: string;
  invitation_details: {
    general_access_right: string;
    has_special_access: boolean;
    specific_service_access?: {
      service: string;
      accessLevel: string;
    }[];
  };
}
export const useListMyConnections = (enabled: boolean) =>
  useGet<AccountInfo[]>(
    SuccessorEndpoints.listMyConnections(),
    {},
    {
      enabled,
    }
  );

export const useAcceptInvitation = () =>
  usePost<{ token: string }, { token: string }>(
    SuccessorEndpoints.acceptInvitation()
  );

export const useSwitchAsGuest = () =>
  usePost<{ id: number | undefined }, { id: number | undefined }>(
    SuccessorEndpoints.switchAsGuest()
  );

export const useActivateHostAccount = () =>
  usePost(SuccessorEndpoints.activateHostAccount());

export const useReportDeath = () => usePost(SuccessorEndpoints.reportDeath());
