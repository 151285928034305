const marriageContractLanding = {
  mainBanner: {
    intro: 'Crea un contratto matrimoniale online:',
    title: 'Legalmente valido, completato in 10 minuti',
    subtitle:
      'Il contratto matrimoniale aiuta voi come coniugi a proteggere il vostro patrimonio e a evitare controversie finanziarie durante o dopo il matrimonio.',
    buttonLabel: 'Crea contratto matrimoniale',
  },
  youtubeTutorial: 'Perché è importante un contratto matrimoniale?',
  aboutCards: {
    protectSpouse: {
      title: 'Proteggere il coniuge',
      description:
        "Il contratto matrimoniale vi protegge, in modo che in caso di decesso non dobbiate vendere la proprietà a causa dell'eredità dei figli.",
    },
    winClarity: {
      title: 'Ottenere chiarezza',
      description:
        'Definite chiaramente in anticipo come verrà gestito il patrimonio e le finanze in caso di decesso.',
    },
    saveDiscussions: {
      title: 'Evitare controversie',
      description:
        'Create chiarezza formalizzando la gestione finanziaria in caso di successione, per evitare controversie.',
    },
  },
  subBanner: {
    title: 'Ben regolato con un contratto matrimoniale conforme alla legge',
    subtitle:
      'Con gut geregelt, potete creare rapidamente il vostro contratto matrimoniale, ricevere consulenza legale su temi delicati o domande aperte e proteggere voi stessi e il vostro coniuge. Create ora e poi fate autenticare dal notaio.',
    buttonLabel: 'Crea ora gratuitamente',
  },
  testimonials: {
    testimonial1: {
      name: 'Daniel Schreiber',
      text: 'Mia moglie ed io abbiamo due figli e un anno fa abbiamo acquistato una proprietà. Con il contratto matrimoniale ci assicuriamo che mia moglie non debba vendere la casa in caso della mia morte per pagare i nostri figli. È stato molto semplice.',
    },
    testimonial2: {
      name: 'Greta Beck',
      text: "Mio marito ed io abbiamo provato 2-3 volte a darci una spinta e affrontare il contratto matrimoniale. gut geregelt non ci ha risparmiato completamente il viaggio dall'avvocato, ma ha reso l'approccio al tema molto più semplice.",
    },
    testimonial3: {
      name: 'Sarah Barmettler',
      text: "Processo semplice e comprensibile. Ci siamo informati in anticipo su Internet e sapevamo cosa volevamo. L'offerta di gut geregelt si è adattata perfettamente. Per l'autenticazione siamo poi andati dal notaio del paese.",
    },
  },
  newsletter: {
    title: 'Diventa ben regolato con il tuo contratto matrimoniale',
    buttonLabel: 'Crea ora',
  },
  faq: {
    questions: {
      question1: "Cos'è un contratto matrimoniale?",
      question2: 'Perché si dovrebbe stipulare un contratto matrimoniale?',
      question3: 'Quando dovrebbe essere stipulato un contratto matrimoniale?',
      question4: 'Come si stipula un contratto matrimoniale in Svizzera?',
      question5:
        'Un contratto matrimoniale può essere modificato successivamente?',
      question6:
        'Cosa succede se non viene stipulato un contratto matrimoniale?',
    },
    answers: {
      answer1:
        'Un contratto matrimoniale è un documento legalmente vincolante stipulato dai coniugi prima o durante il matrimonio. Regola i rapporti patrimoniali dei coniugi e può contenere accordi individuali sulla divisione del patrimonio, la responsabilità dei debiti e il mantenimento in caso di divorzio o decesso.',
      answer2:
        'Un contratto matrimoniale offre la possibilità di regolare chiaramente e individualmente le questioni finanziarie dei coniugi. Questo può aiutare a evitare malintesi e controversie e a stabilire regole chiare in caso di divorzio o decesso. È particolarmente utile in caso di patrimoni o redditi diversi tra i coniugi, partecipazioni aziendali o famiglie allargate.',
      answer3:
        "Un contratto matrimoniale può essere stipulato in qualsiasi momento prima o durante il matrimonio. Tuttavia, è consigliabile stipularlo prima del matrimonio per creare condizioni chiare fin dall'inizio.",
      answer4:
        'In Svizzera, un contratto matrimoniale deve essere stipulato in forma pubblica. Ciò significa che deve essere esaminato e autenticato da un notaio. Entrambi i coniugi devono essere presenti personalmente e firmare il contratto.',
      answer5:
        "Sì, un contratto matrimoniale può essere modificato o revocato in qualsiasi momento di comune accordo tra i coniugi. Anche in questo caso è necessaria un'autenticazione pubblica da parte di un notaio.",
      answer6:
        'Senza un contratto matrimoniale, si applica il regime patrimoniale ordinario della Svizzera, che prevede la partecipazione agli acquisti. Ciò significa che il patrimonio acquisito durante il matrimonio viene diviso a metà tra i coniugi in caso di divorzio, mentre il patrimonio pre-matrimoniale e le eredità sono considerati beni propri e non vengono presi in considerazione nella divisione.',
    },
  },
  situations: {
    heirat: {
      content: {
        main: {
          title: 'Accordo prematrimoniale',
          description:
            'Un accordo prematrimoniale regola come i vostri beni saranno divisi durante il matrimonio e in caso di separazione o divorzio. Ciò crea condizioni chiare ed evita incertezze.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'Il momento migliore per un accordo prematrimoniale è subito dopo il matrimonio. Questo assicura che entrambi i partner siano trattati in modo equo se le vostre circostanze di vita cambiano.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Chiara divisione dei beni',
              'Protezione di entrambi i partner',
              'Prevenzione dei conflitti',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Ulteriori informazioni',
        startProcess: 'Avvia il processo',
      }
    },
    immobilienkauf: {
      content: {
        main: {
          title: 'Accordo prematrimoniale',
          description:
            'Un accordo prematrimoniale regola la divisione dei beni, soprattutto se si acquista un immobile insieme. Questo protegge entrambi i partner e assicura che tutto sia chiaramente regolamentato.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'Quando si acquista un immobile, si pone la questione della divisione dei beni. Un accordo prematrimoniale crea chiarezza e assicura che tu e il tuo partner siate protetti in caso di separazione o di evento imprevisto.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Chiare regolamentazioni dei beni per le proprietà comuni',
              'Protezione di entrambi i partner in caso di separazione o decesso',
              'Prevenzione di successive controversie',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Ulteriori informazioni',
        startProcess: 'Avvia il processo',
      }
    },
    'geburt-eines-kindes': {
      content: {
        main: {
          title: 'Accordo prematrimoniale',
          description:
            'Con un accordo prematrimoniale, si creano regole chiare per quanto riguarda i beni e la sicurezza di tuo figlio. Questo garantisce protezione se le vostre circostanze di vita cambiano.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'Ora che un bambino fa parte della tua famiglia, un accordo prematrimoniale offre una protezione aggiuntiva per i beni di tuo figlio. Crea condizioni chiare per la distribuzione dei beni e il mantenimento di tuo figlio.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Protezione dei beni del bambino',
              'Regole chiare in caso di separazione',
              'Prevenzione delle controversie',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Ulteriori informazioni',
        startProcess: 'Avvia il processo',
      }
    },
  },
};

export default marriageContractLanding;
