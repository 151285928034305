const pensionProvision = {
  title: 'gut geregelt helps you protect your loved ones and yourself',
  alreadyHaveAcc: 'Already have an account?',
  signIn: 'Sign in',
  p1: 'The gut geregelt team is available to assist you with important topics that everyone should address. We start with three important topics for retirement and pension planning and will continuously expand our offerings to meet your needs.',
  p2: 'We accompany you like a personal trainer through important processes you need to tackle to avoid problems for yourself and your loved ones in difficult situations.',
  p3: 'Let’s discuss the details. We recommend that everyone should have completed and finalized the following legal documents by the age of 50:',
  p3a: 'If you start now and take 30 minutes, we guarantee that you will have taken a big step for your and your family’s future security.',
  p4: 'Based on your responses, there is a low risk that, like most of our customers, you may not be able to take the necessary steps to complete these documents at the first attempt.',
  p4a: 'Most of our customers do not manage to take the necessary steps to complete everything at the first attempt.',
  p5a: 'To support you in this, we offer a reminder service.',
  p5b: 'For this, we now need some data from you so that we can remind you of any open steps.',
  p6a: 'Therefore, we would like to offer you our reminder service as support.',
  p6b: 'Please provide us with a communication channel through which we can remind you of the open steps.',

  onboarding: {
    stepOne: {
      title: 'Pension check',
      subtitle: 'First, we would like to get to know you a little better',
      questions: [
        {
          question: 'First question: for whom do you want to make provisions?',
          options: [{ label: 'Myself' }, { label: 'Family member' }],
        },
        {
          question: '{{referringTo}}',
          options: [{ label: 'Yes' }, { label: 'No' }],
        },
      ],
      theirRealEstate: 'Does your family member own real estate?',
      myRealEstate: 'Do you own real estate?',
    },
    welcomeCard: {
      title: 'Welcome to gut geregelt',
      successMessage: 'Your account has been created successfully',
      emailMessage: 'An email with your access link is on its way',
      nextStep: 'Next step',
      stepOne: 'Open the link in the email',
      stepTwo: 'Create your personal password',
      stepThree: 'Start with your provision planning',
    },
    stepTwo: {
      title: 'Pension check',
      description: 'What people similar to you use gut geregelt for.',
      subtitlePersonal:
        'Thank you, {{genderFormal}} {{first_name}} {{last_name}}! We recommend people like you to ensure that you have or consider the following artifacts or services.',
      femaleGenderBased: 'dear',
      maleGenderBased: 'dear',
      subtitleOther:
        'Thank you! For people like {{first_name}} {{last_name}}, we recommend ensuring that they consider the following artifacts or services (based on gender).',
      selectOnlyCompleted: 'Click on the ones you already have.',
      seeResults: 'Show results',
      noCompletedService: 'I have not completed any of the options',
    },
    stepThree: {
      title: 'How can we help?',
      stepByStep: 'Step by step to better-organized retirement planning.',
      redLightLabel: 'You have a long way to go.',
      yellowLightLabel: 'There is work ahead of you!',
      greenLightLabel: 'You are well organized',
      redLightRecommendation: {
        title:
          'But don’t worry, if you take one step at a time, you will quickly reach your goal. Take our word for it. Just start today with the first step.',
      },
      yellowLightRecommendation: {
        title:
          'Good, you have already taken care of {{list}}. We therefore recommend that you continue with the following things:',
      },
      greenLightRecommendation: {
        title:
          'Wow! You have already done everything that people similar to you need to complete. Please look at our other topics that could add value for you.',
      },
      subtitle:
        'Experience shows that pension issues are often triggered by life events such as marriage, children, or age.',
      provisionTopics: 'Provision topics',
      provisionTopicsOptions: {
        financialAdvise: 'Financial advisor',
        legalAdvise: 'Legal advice',
      },
      lifeSituations: 'Life situations',
      lifeSituationsOptions: {
        marriage: 'Marriage',
        birthOfChild: 'Birth of a child',
        purchaseOfRealEstate: 'Purchase of real estate',
        enteringRetirement: 'Entering retirement',
        illnes: 'Disease diagnosis',
        deathOfARelative: 'Death of a relative',
        foundingACompany: 'Founding a company',
        divorce: 'Divorce',
      },
    },
  },

  confirmCheckbox:
    'By selecting this checkbox, I acknowledge and confirm that I agree to be registered on gut geregelt.',
  processingAgreement: 'See our data processing agreement',

  c1: {
    title: 'Living will',
    description:
      'allows you to specify how medical professionals should proceed in an emergency.',
  },
  c2: {
    title: 'Power of attorney',
    description:
      'avoids problems with the KESB and regulates guardianship for your children.',
  },
  c3: {
    title: 'Last will',
    description: 'to leave your legacy based on your wishes.',
  },

  questions: [
    {
      question:
        'If you have the choice to do something today or postpone it to tomorrow... What do you do?',
      options: [
        { label: 'I do it immediately.' },
        { label: 'I don’t know.' },
        { label: 'I postpone it to tomorrow.' },
      ],
    },
    {
      question:
        'If someone tries to motivate you to do something you don’t like... What works best?',
      options: [
        { label: 'Incentives get me moving.' },
        { label: 'I don’t know.' },
        { label: 'It makes me feel bad.' },
      ],
    },
    {
      question: 'How do you usually approach things?',
      options: [
        { label: 'Close my eyes and just do it.' },
        { label: 'I don’t know.' },
        { label: 'Rather later.' },
      ],
    },
    {
      question:
        'Tell us how often you would like to be reminded if you temporarily pause the process:',
      options: [
        { label: 'Weekly' },
        { label: 'Monthly' },
        { label: 'Quarterly' },
      ],
    },
  ],

  thankyouCard: {
    title: 'Simple and uncomplicated retirement planning',
    thankYou: 'Thank you for your interest in gut geregelt',
    gentlyReminder:
      'We will gently remind you until you have all relevant documents completed.',
  },

  lifesituationCheck: {
    title: 'Life situation check',
    description:
      'Each phase of life has its own unique topics. Find out what is relevant for the phase you are in.',
  },
};

export default pensionProvision;
