const digitalFootprintLanding = {
  mainBanner: {
    title:
      'Régulez et sécurisez votre héritage numérique dans un coffre-fort sécurisé',
    subtitle:
      'Assurez-vous que toutes vos données électroniques ou actifs, appareils et assurances sont sécurisés et peuvent être accessibles aux personnes autorisées en cas de décès.',
    buttonLabel: "Sécuriser l'héritage",
  },
  youtubeTutorial: 'Comment préserver votre héritage numérique ?',
  aboutCards: {
    documentAccounts: {
      title: 'Documentez vos comptes',
      description:
        'Transférez facilement tous les comptes de votre gestionnaire de mots de passe dans gut geregelt. Ne vous inquiétez pas. Nous ne voulons explicitement pas de mots de passe !',
    },
    connectDigitalValues: {
      title: 'Cartographier les valeurs numériques',
      description:
        'Listez tous vos portefeuilles numériques, domaines et autres valeurs et décrivez où et comment votre famille peut trouver les mots de passe pour y accéder.',
    },
    preserveDigitalData: {
      title: 'Préserver les données numériques',
      description:
        "Organisez et conservez des données telles que des images ou des vidéos, de la musique, des documents importants et d'autres données dans un coffre-fort sécurisé.",
    },
  },
  subBanner: {
    title: 'Assurez-vous de tout laisser propre et bien rangé',
    subtitle:
      'Sauf disposition expresse dans votre dernier testament ou contrat de testament, vos actifs numériques seront transmis à vos héritiers légaux en cas de décès. Vous devriez donc décider de votre vivant ce qui doit être supprimé ou transféré après votre décès.',
    buttonLabel: "Réguler l'empreinte numérique",
  },
  testimonials: {
    testimonial1: {
      name: 'Patrick Zesiger',
      text: "L'héritage numérique de gut geregelt m'a aidé à garder mes affaires numériques en parfait ordre. Un must absolu pour quiconque fait des choses dans le monde numérique !",
    },
    testimonial2: {
      name: 'Sara Becker',
      text: "La certitude que ma famille n'aura pas à s'occuper du nettoyage et que tous mes canaux de médias sociaux pourront être fermés me donne un bon sentiment. Super facile à configurer. Je ne peux que le recommander.",
    },
    testimonial3: {
      name: 'Luisa Stauffer',
      text: "J'ai récemment mis en place l'héritage numérique avec et pour mon père et ma mère. Cela a été très rapide et simple. Même mon père était content que nous ayons enfin réglé cela.",
    },
  },
  newsletter: {
    title: "Soyez bien organisé dans l'héritage numérique",
    buttonLabel: 'Organiser maintenant',
  },
  faq: {
    questions: {
      question1:
        "Qu'est-ce qu'un héritage numérique et pourquoi est-il important ?",
      question2:
        "Quels types d'actifs numériques peuvent être inclus dans l'héritage numérique ?",
      question3: "Comment fonctionne le service d'héritage numérique ?",
      question4:
        "Comment puis-je m'assurer que mes proches ont accès à mon héritage numérique ?",
      question5:
        'Que se passe-t-il avec mes actifs numériques lorsque je meurs ?',
      question6:
        'Quelle est la sécurité de mes informations personnelles avec votre service ?',
      question7: 'Puis-je mettre à jour ou modifier mon héritage numérique ?',
      question8:
        "Quels aspects juridiques doivent être pris en compte lors de la gestion d'un héritage numérique ?",
      question9:
        'Que se passe-t-il avec mes abonnements en ligne et mes comptes après ma mort ?',
      question10:
        "Comment votre service soutient-il la gestion des documents d'assurance et bancaires ?",
      question11:
        "gut geregelt stocke-t-il des mots de passe ou d'autres données d'accès ?",
    },
    answers: {
      answer1:
        "L'héritage numérique comprend tous les actifs numériques et les informations stockées en ligne d'une personne. Cela peut aller des comptes de médias sociaux aux abonnements en ligne en passant par les portefeuilles de cryptomonnaie. Il est important de gérer l'héritage numérique pour s'assurer que vos affaires numériques sont traitées correctement après votre décès et pour faciliter l'accès à des informations importantes pour vos proches.",
      answer2:
        "Notre service vous permet de capturer une variété d'actifs numériques, y compris les comptes de médias sociaux, les abonnements en ligne, les portefeuilles de cryptomonnaie, les domaines, les gestionnaires de mots de passe, les appareils électroniques et bien plus encore. Vous pouvez documenter tous les actifs numériques pertinents et laisser des instructions sur ce qu'il faut faire avec eux après votre décès et comment y accéder.",
      answer3:
        "Notre service vous offre une plateforme conviviale où vous pouvez capturer, gérer vos actifs numériques et définir des instructions pour la gestion de vos actifs numériques après votre décès. Vous pouvez déterminer qui doit avoir accès à quelles informations et définir ce qu'il advient de vos actifs numériques.",
      answer4:
        'Avec notre service, vous pouvez désigner des personnes spécifiques qui auront accès à votre héritage numérique. Vous pouvez également laisser des instructions détaillées sur la façon dont vos actifs numériques doivent être gérés pour vous assurer que vos proches ont accès à des informations importantes.',
      answer5:
        "Après votre décès, les personnes que vous avez désignées auront accès à votre compte gut geregelt en présentant le certificat de décès et la procuration de l'exécuteur testamentaire. Cela garantit qu'elles peuvent gérer vos actifs numériques conformément à vos instructions. Vous pouvez déterminer si certains comptes doivent être supprimés, poursuivis ou transférés à certaines personnes. Notre service garantit que vos affaires numériques sont traitées selon vos souhaits.",
      answer6:
        "Nous prenons très au sérieux la sécurité de vos informations personnelles. Notre service utilise des mesures de sécurité standard de l'industrie pour protéger vos données, y compris des technologies de cryptage et des restrictions d'accès. De plus, nous ne stockons pas de données sensibles telles que les mots de passe, les soldes de comptes, etc.",
      answer7:
        "Oui, vous pouvez mettre à jour ou modifier votre héritage numérique à tout moment. Notre plateforme vous permet d'ajouter de nouveaux actifs, de supprimer ou de modifier des informations existantes et de laisser de nouvelles instructions.",
      answer8:
        "Lors de la gestion d'un héritage numérique, des aspects juridiques tels que la rédaction d'un testament et la désignation des héritiers doivent être pris en compte. Il est conseillé de demander un avis juridique pour s'assurer que vos affaires numériques sont traitées conformément aux lois en vigueur.",
      answer9:
        'Après votre décès, vos abonnements en ligne et vos comptes peuvent être gérés selon vos instructions. Vous pouvez déterminer si certains abonnements doivent être annulés, des comptes supprimés ou transférés à certaines personnes.',
      answer10:
        "Notre service offre des fonctionnalités pour organiser vos relations d'assurance et bancaires. Vous pouvez indiquer où se trouvent quels documents. Nous aidons également à la création automatique de lettres de résiliation pour les polices d'assurance après votre décès, dès qu'un certificat de décès et une procuration de l'exécuteur testamentaire sont disponibles.",
      answer11:
        "Non. Nous soulignons expressément cela et offrons même un soutien pour que vous, en tant que client, NE stockiez ou n'affichiez PAS de mots de passe dans gut geregelt. Au lieu de cela, nous vous encourageons à stocker les informations d'accès pour les comptes pertinents séparément et à les conserver dans un endroit sûr auquel votre famille a accès. Vous documentez cet endroit sûr pour vos proches dans gut geregelt.",
    },
  },
  situations: {
    'tod-verwandter': {
      content: {
        main: {
          title: 'Héritage numérique',
          description:
            "L'héritage numérique régit ce qui arrive à vos comptes en ligne, à vos réseaux sociaux et à vos données numériques après votre décès. Vous décidez de ce qui doit être supprimé, transféré ou continué.",
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              'De nos jours, nous laissons une multitude de traces numériques. En réglant votre héritage numérique, vous évitez que des données sensibles ne restent incontrôlées sur le net et vous soulagez vos proches.',
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              'Contrôle de vos données numériques',
              "Protection contre l'usurpation d'identité",
              'Soulagement de vos proches',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'En savoir plus',
        startProcess: 'Démarrer le processus',
      }
    },

    pensionierung: {
      content: {
        main: {
          title: 'Héritage numérique',
          description:
            "Votre vie numérique laisse des traces, des comptes en ligne aux médias sociaux. Avec une réglementation pour votre héritage numérique, vous déterminez ce qu'il advient de vos données après votre décès.",
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              "À l'ère numérique, il est important de réglementer également l'héritage numérique afin de garantir le traitement des données sensibles.",
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              'Protection de votre identité numérique',
              'Éviter les abus',
              'Soulagement des proches',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'En savoir plus',
        startProcess: 'Démarrer le processus',
      }
    },

    unternehmensgruendung: {
      content: {
        main: {
          title: 'Héritage numérique',
          description:
            "L'héritage numérique régit ce qui arrive à vos comptes en ligne, à vos réseaux sociaux et à vos données numériques après votre décès. Vous décidez de ce qui doit être supprimé, transféré ou continué.",
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              'De nos jours, nous laissons une multitude de traces numériques. En réglant votre héritage numérique, vous évitez que des données sensibles ne restent incontrôlées sur le net et vous soulagez vos proches.',
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              'Contrôle de vos données numériques',
              "Protection contre l'usurpation d'identité",
              'Soulagement de vos proches',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'En savoir plus',
        startProcess: 'Démarrer le processus',
      }
    },
  },
};

export default digitalFootprintLanding;
