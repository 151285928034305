const financialPlanning = {
  situations: {
    pensionierung: {
      content: {
        main: {
          title: 'Beratung zur Finanzsituation',
          description:
            'Im Ruhestand ändert sich oft die finanzielle Situation. Unsere Beratung hilft Ihnen, Ihre Finanzen optimal zu planen, damit Sie auch im Alter finanziell abgesichert sind.',
        },
        columns: [
          {
            title: 'Warum jetzt',
            description: [
              'Eine gute Finanzplanung gibt Ihnen im Ruhestand Sicherheit und sorgt dafür, dass Sie Ihre neue Lebensphase entspannt genießen können.',
            ],
          },
          {
            title: 'Ihre Vorteile',
            description: [
              'Optimale Nutzung Ihrer Renteneinkünfte',
              'Steuerliche Vorteile',
              'Langfristige finanzielle Absicherung',
            ],
          },
        ],
        ctas: {
          learnMore: 'Mehr erfahren',
          startProcess: 'Prozess starten',
        }
      },
    },

    scheidung: {
      content: {
        main: {
          title: 'Finanzplanung',
          description:
            'Eine umfassende Finanzplanung hilft Ihnen, Ihre finanzielle Situation nach der Scheidung neu zu ordnen und langfristig abzusichern. So behalten Sie die Kontrolle über Ihre Finanzen.',
        },
        columns: [
          {
            title: 'Warum jetzt',
            description: [
              'Nach einer Scheidung müssen finanzielle Prioritäten oft neu gesetzt werden. Eine professionelle Finanzplanung gibt Ihnen Klarheit und Sicherheit für die kommenden Jahre.',
            ],
          },
          {
            title: 'Ihre Vorteile',
            description: [
              'Neuordnung Ihrer finanziellen Situation',
              'Planung für langfristige finanzielle Stabilität',
              'Steuerliche Optimierung nach der Scheidung',
            ],
          },
        ],
        ctas: {
          learnMore: 'Mehr erfahren',
          startProcess: 'Prozess starten',
        }
      },
    },
  },
};

export default financialPlanning;
