import { IQuestion } from '#/types/globalTypes';

const powerOfTrustQuestions: IQuestion[] = [
  {
    question: 'My trusted person for me as an individual is:',
  },
  {
    question:
      'Taking responsibility for another person is often associated with significant effort. Would you like to pay your trusted person a fee?',
    options: [
      {
        label: 'Yes, compensation according to local and industry standards',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'If the above-mentioned person is not available, I would like to appoint the following person as my trusted person:',
  },
  {
    question:
      'Taking responsibility for another person is often associated with significant effort. Would you like to pay your alternative trusted person a fee?',
    options: [
      {
        label: 'Yes, compensation according to local and industry standards',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question: 'Do you have children under 18 years of age?',
    options: [
      {
        label: 'Yes',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question: 'Who should be the trusted person for your children?',
  },
  {
    question: 'Role: ',
  },
  {
    question:
      'Would you like to pay your trusted person a fee for being responsible for your children?',
    options: [
      {
        label: 'Yes, compensation according to local and industry standards',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'If this person is not available, I would like the following person to be responsible for my children:',
  },
  {
    question:
      'Would you like to pay your alternative trusted person a fee for caring for your children?',
    options: [
      {
        label: 'Yes, compensation according to local and industry standards',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question: 'I entrust the management of my assets to the following person:',
  },
  {
    question:
      'Would you like to pay your trusted person a fee for managing your assets?',
    options: [
      {
        label: 'Yes, compensation according to local and industry standards',
      },
      {
        label: 'No',
      },
    ],
  },
  {
    question:
      'If this person is not available, I would like the following person to be responsible for my assets:',
  },
  {
    question:
      'Would you like to pay your alternative trusted person a fee for managing your assets?',
    options: [
      {
        label: `Yes,
                compensation in accordance with local and industry standards`,
      },
      {
        label: 'No',
      },
    ],
  },
];

export default powerOfTrustQuestions;
