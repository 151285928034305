const poaLanding = {
  mainBanner: {
    intro: 'Crea una procura online:',
    title: 'Legalmente valido, completato in 5 minuti',
    subtitle:
      'Senza una procura, il tuo coniuge o la tua famiglia non possono prendere decisioni per te in caso di emergenza. Decidi ora chi è legalmente autorizzato ad agire a tuo nome.',
    buttonLabel: 'Crea procura',
  },
  youtubeTutorial: 'Perché è importante una procura?',
  aboutCards: {
    guaranteeForPower: {
      title: 'Garanzia di autorità',
      description:
        'Permetti ai tuoi cari di prendere decisioni nel tuo migliore interesse quando non puoi farlo da solo.',
    },
    mutualProtection: {
      title: 'Protezione reciproca',
      description:
        "Proteggi l'integrità dei tuoi beni assicurandoti che le decisioni importanti per la famiglia siano prese solo dai membri della famiglia.",
    },
    clarificationForFamily: {
      title: 'Chiarezza per la famiglia',
      description:
        'Elimina discussioni inutili e fornisci chiarezza ai tuoi cari.',
    },
  },
  subBanner: {
    title: 'Ben regolato con una procura conforme alla legge',
    subtitle:
      'Con gut geregelt, puoi creare rapidamente la tua procura, ricevere consulenza legale su temi delicati o domande aperte e fare un favore ai tuoi cari.',
    buttonLabel: 'Crea ora gratuitamente',
  },
  situations: {
    'geburt-eines-kindes': {
      content: {
        main: {
          title: 'Procura',
          description:
            'Una procura determina chi prenderà le decisioni per te e la tua famiglia se non sei più in grado di farlo da solo. Proteggi te stesso e il tuo bambino con disposizioni chiare.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'Con la nascita di un figlio arriva una responsabilità aggiuntiva. Una procura garantisce che il tuo bambino sarà ben assistito anche in caso di emergenza.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Determina chi si prenderà cura del tuo bambino',
              'Sicurezza legale per la tua famiglia',
              'Protezione in caso di malattia o emergenza',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Ulteriori informazioni',
        startProcess: 'Avvia il processo',
      }
    },
    immobilienkauf: {
      content: {
        main: {
          title: 'Procura',
          description:
            'Una procura determina chi si occuperà dei tuoi beni e dei tuoi affari personali se non sei più in grado di farlo da solo. Questo protegge la tua casa e la tua famiglia.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              "Con l'acquisto di una proprietà aumenta la responsabilità. Una procura garantisce che la tua proprietà rimanga protetta anche in emergenza e che i tuoi desideri vengano rispettati.",
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Protezione della tua casa in caso di malattia o incidente',
              'Sicurezza per la tua famiglia',
              'Chiarezza legale per le emergenze',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Ulteriori informazioni',
        startProcess: 'Avvia il processo',
      }
    },
    pensionierung: {
      content: {
        main: {
          title: 'Procura',
          description:
            'La procura specifica chi gestirà i tuoi affari se non sei più in grado di farlo da solo. Questo garantisce che la tua vita e i tuoi beni siano gestiti secondo i tuoi desideri.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              "Con l'età della pensione, deve essere stabilito anche chi si occuperà dei tuoi affari finanziari e personali in caso di emergenza.",
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Disposizioni chiare in caso di incapacità',
              'Protezione dei beni e dei diritti',
              'Sollievo per la famiglia',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Ulteriori informazioni',
        startProcess: 'Avvia il processo',
      }
    },
    unternehmensgruendung: {
      content: {
        main: {
          title: 'Procura',
          description:
            'Una procura determina chi subentrerà nei tuoi affari professionali e privati se non sei più in grado di gestirli da solo. Questo assicura la continuità della tua azienda e protegge la tua famiglia.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'Come imprenditore, porti una grande responsabilità – non solo per te stesso, ma anche per i tuoi dipendenti e partner commerciali. Una procura garantisce che la tua azienda rimanga operativa anche in tempi di crisi.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              "Protezione dell'azienda in caso di malattia o incidente",
              'Continuità della gestione aziendale in emergenza',
              'Sollievo per la famiglia',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Ulteriori informazioni',
        startProcess: 'Avvia il processo',
      }
    },
    scheidung: {
      content: {
        main: {
          title: 'Procura',
          description:
            'Una procura specifica chi gestirà i tuoi affari personali e finanziari se non sei più in grado di farlo. Particolarmente dopo un divorzio, è importante ridefinire queste disposizioni.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'Dopo un divorzio, spesso cambiano molti quadri giuridici. Una procura aggiornata garantisce che i tuoi desideri siano considerati nella tua nuova situazione di vita.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Nuova organizzazione dei tuoi affari personali',
              'Protezione in caso di malattia',
              'Chiarezza e sicurezza per il futuro',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Ulteriori informazioni',
        startProcess: 'Avvia il processo',
      }
    },
  },
  newsletter: {
    title: 'Diventa ben regolato con la tua procura',
    buttonLabel: 'Crea ora',
  },
  faq: {
    questions: {
      question1: "Cos'è una procura?",
      question2: "Quando si applica l'incapacità?",
      question3: 'Cosa succede se divento incapace e non ho una procura?',
      question4: 'Cosa devo includere nella procura?',
      question5: 'Come creo la mia procura?',
      question6: 'Come scelgo il mio rappresentante?',
      question7: "Cos'è la KESB e qual è il suo ruolo?",
      question8: 'Posso modificare la mia procura quando voglio?',
      question9: 'Posso revocare la mia procura?',
      question10:
        "Posso includere i miei desideri di trattamento medico per un'emergenza nella procura?",
      question11:
        'Ci sono altri documenti che dovrei creare oltre alla procura?',
      question12: 'Come mi aiuta gut geregelt con la mia procura?',
    },
    answers: {
      answer1:
        'In una procura, specifichi chi rappresenterà i tuoi interessi in questioni personali e finanziarie in caso di incapacità. Con una procura, ti assicuri che i tuoi interessi siano tutelati e che i tuoi desideri siano rispettati.',
      answer2:
        "L'incapacità può verificarsi, ad esempio, a causa di una malattia grave come la demenza, dopo un ictus o in seguito a un grave incidente che porta al coma.",
      answer3:
        'Dipende dal tuo stato civile, sposato o in unione registrata, o convivente.',
      answer4:
        'Una procura include tre aree: assistenza, cura finanziaria e transazioni legali. Puoi assegnare tutte le aree allo stesso rappresentante o dividerle tra più rappresentanti.',
      answer5:
        'Puoi creare la tua procura in modo indipendente; non dimenticare di datarla e firmarla. In alternativa, puoi crearla con un notaio e farla certificare pubblicamente.',
      answer6:
        'Puoi scegliere una persona per tutte e tre le aree o nominare più persone. È fondamentale che tu abbia completa fiducia in questa persona.',
      answer7:
        "L'Autorità di protezione dei minori e degli adulti (KESB) garantisce la protezione dei bambini e degli adulti vulnerabili. Nel caso della procura, la KESB interviene se viene informata dell'incapacità di una persona.",
      answer8:
        "Sì, con l'unico requisito di essere capace al momento della modifica. Una nuova procura sostituisce la versione precedente.",
      answer9:
        'Sì, puoi revocare la tua procura in qualsiasi momento distruggendo il documento.',
      answer10:
        'Sì, puoi farlo. Tuttavia, potrebbe avere senso creare una direttiva del paziente separata.',
      answer11:
        'Sì, il {{lastWill}} e la {{livingWill}} sono due altri documenti importanti che dovresti preparare tempestivamente.',
      answer12:
        'In gut geregelt, ti assistiamo nella creazione di una procura legalmente valida e senza intoppi.',
    },
    hyperlinks: {
      lastWill: 'Testamento',
      livingWill: 'Direttiva del paziente',
      powerOfAttorney: 'Procura',
    },
  },
};

export default poaLanding;
