import { IQuestion } from '#/types/globalTypes';

const powerOfTrustQuestions: IQuestion[] = [
  {
    question: 'Meine Vertrauensperson für mich als Person ist:',
  },
  {
    question:
      'Die Übernahme von Verantwortung für eine andere Person ist oft mit erheblichem Aufwand verbunden. Möchten Sie der Person Ihres Vertrauens ein Honorar zahlen?',
    options: [
      {
        label:
          'Ja, ' +
          ' Entschädigung gemäss den örtlichen und branchenüblichen Standards',
      },
      {
        label: 'Nein',
      },
    ],
  },
  {
    question:
      'Wenn die oben genannte Person nicht verfügbar ist, möchte ich die folgende Person zu meiner Vertrauensperson ernennen:',
  },
  {
    question:
      'Die Übernahme von Verantwortung für eine andere Person ist oft mit erheblichem Aufwand verbunden. Möchten Sie Ihrer alternativen Vertrauensperson ein Honorar zahlen?',
    options: [
      {
        label:
          'Ja, ' +
          'Entschädigung gemäss den örtlichen und branchenüblichen Standards',
      },
      {
        label: 'Nein',
      },
    ],
  },
  {
    question: 'Haben Sie Kinder unter 18 Jahren?',
    options: [
      {
        label: 'Ja',
      },
      {
        label: 'Nein',
      },
    ],
  },
  {
    question: 'Wer sollte die Vertrauensperson für Ihre Kinder sein?',
  },
  {
    question: 'Rolle: ',
  },
  {
    question:
      'Möchten Sie Ihrer Vertrauensperson eine Gebühr dafür zahlen, dass sie für Ihre Kinder verantwortlich ist?',
    options: [
      {
        label:
          'Ja, ' +
          'Entschädigung gemäss den örtlichen und branchenüblichen Standards',
      },
      {
        label: 'Nein',
      },
    ],
  },
  {
    question:
      'Wenn diese Person nicht verfügbar ist, möchte ich, dass die nachfolgende Person für meine Kinder verantwortlich ist:',
  },
  {
    question:
      'Möchten Sie Ihrer alternativen Vertrauensperson eine Gebühr für die Betreuung Ihrer Kinder zahlen?',
    options: [
      {
        label:
          'Ja, ' +
          'Entschädigung gemäss den örtlichen und branchenüblichen Standards',
      },
      {
        label: 'Nein',
      },
    ],
  },
  {
    question:
      'Die Verwaltung meines Vermögens vertraue ich der nachfolgenden Person an:',
  },
  {
    question:
      'Möchten Sie Ihrer Vertrauensperson eine Gebühr für die Verwaltung Ihres Vermögens zahlen?',
    options: [
      {
        label:
          'Ja, ' +
          'Entschädigung gemäss den örtlichen und branchenüblichen Standards',
      },
      {
        label: 'Nein',
      },
    ],
  },
  {
    question:
      'Wenn diese Person nicht verfügbar ist, möchte ich dass die nachfolgende Person für mein Vermögen verantwortlich ist:',
  },
  {
    question:
      'Möchten Sie Ihrer alternativen Vertrauensperson ein Honorar für die Verwaltung Ihres Vermögens zahlen?',
    options: [
      {
        label: `Ja,
                Entschädigung im Einklang mit lokalen und branchenüblichen Standards`,
      },
      {
        label: 'Nein',
      },
    ],
  },
];

export default powerOfTrustQuestions;
