const financialPlanning = {
  situations: {
    pensionierung: {
      content: {
        main: {
          title: 'Consulenza sulla situazione finanziaria',
          description:
            'In pensione, la situazione finanziaria spesso cambia. La nostra consulenza ti aiuta a pianificare al meglio le tue finanze, in modo che tu sia finanziariamente sicuro anche in età avanzata.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'Una buona pianificazione finanziaria ti dà sicurezza in pensione e garantisce che tu possa goderti la tua nuova fase della vita in modo rilassato.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Utilizzo ottimale del tuo reddito pensionistico',
              'Vantaggi fiscali',
              'Sicurezza finanziaria a lungo termine',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Ulteriori informazioni',
        startProcess: 'Avvia il processo',
      }
    },

    scheidung: {
      content: {
        main: {
          title: 'Pianificazione finanziaria',
          description:
            'Una pianificazione finanziaria completa ti aiuta a riorganizzare la tua situazione finanziaria dopo il divorzio e a metterla in sicurezza a lungo termine. In questo modo mantieni il controllo delle tue finanze.',
        },
        columns: [
          {
            title: 'Perché ora',
            description: [
              'Dopo un divorzio, le priorità finanziarie devono spesso essere ridefinite. Una pianificazione finanziaria professionale ti dà chiarezza e sicurezza per gli anni a venire.',
            ],
          },
          {
            title: 'I tuoi vantaggi',
            description: [
              'Riorganizzazione della tua situazione finanziaria',
              'Pianificazione per la stabilità finanziaria a lungo termine',
              'Ottimizzazione fiscale dopo il divorzio',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Ulteriori informazioni',
        startProcess: 'Avvia il processo',
      }
    },
  },
};

export default financialPlanning;
