const metadata = {
  main: {
    title: 'Gute Vorsorge mit gut geregelt - Ihr Schweizer Vorsorgeportal',
    description:
      'Endlich gut vorsorgen: Alle wichtigen Vorsorgedokumente online erstellen! Rechtssicher ✓ In 10 min erledigt ✓ Bequem von zuhause ✓ Schweizer Vorsorgeplattform ✓',
    keywords: {
      livingWill: 'Patientenverfügung',
      powerOfAttorney: 'Vorsorgeauftrag',
      lastWill: 'Testament',
      personalPreferences: 'Persönliche Vorsorge',
      retirementPlanning: 'Pensionsplanung',
      retirementProvision: 'Altersvorsorge',
    },
  },
  living_will: {
    title: 'Schweizer Patientenverfügung online erstellen in 5 min',
    description:
      'In 5min gut vorgesorgt: Schweizer Testament online erstellen mit gut geregelt.  Rechtssicher ✓ Bequem von zuhause ✓ Schweizer Anbieter ✓ ',
    keywords: {
      livingWill: 'Patientenverfügung',
      printLivingWill: 'Patientenverfügung ausdrucken',
      directive: 'Patientenverfuegung',
      printDirective: 'Patientenverfügungen zum ausdrucken',
    },
  },
  power_of_attorney: {
    title: 'Schweizer Vorsorgeauftrag online erstellen in 5 min',
    description:
      'In 5 min gut vorgesorgt mit einem Schweizer Vorsorgeauftrag. Schutz für die Familie ✓ Rechtssicher ✓ Bequem von zuhause ✓ Schweizer Anbieter ✓ ',
    keywords: {
      powerOfAttorney: 'Vorsorgeauftrag',
      powerOfAttorneyTemplate: 'Vorsorgeauftrag Vorlage',
      powerOfAttorneyTemplate2: 'Vorlage Vorsorgeauftrag',
      powerOfAttorneyTemplate3: 'Vorlage für Vorsorgeauftrag',
      powerOfAttorney2: 'Vorsorgeauftrag',
    },
  },
  last_will: {
    title: 'Schweizer Testament online erstellen in 10 min',
    description:
      'In 10 min gut vorgesorgt: Schweizer Testament rechtssicher online erstellen. Klarheit für Angehörige ✓ Einfach ✓ Bequem von zuhause ✓ Schweizer Anbieter ✓',
    keywords: {
      lastWill: 'Testament',
      share: 'Pflichtteil Erbe',
      share2: 'Erbe Pflichtteil',
      estate: 'Nachlass',
      succession: 'Gesetzliche Erbfolge',
    },
  },
  marriage_contract: {
    title: 'Schweizer Ehevertrag in 5min online erstellen',
    description:
      'In 10 min gut vorgesorgt: Schweizer Ehevertrag online erstellen mit gut geregelt.  Rechtssicher ✓ Bequem von zuhause ✓ Schweizer Anbieter ✓ ',
    keywords: {
      lastWill: 'Ehevertrag',
      share: 'Pflichtteil Erbe',
      share2: 'Ehevetrag Immobilienkauf',
      estate: 'Nachlass',
      succession: 'Gesetzliche Erbfolge',
    },
  },
  partner_with_us: {
    title: 'Erweitern Sie Ihr Vorsorgeangebot mit gut geregelt',
    description:
      'Nutzen Sie die Whitelabel-Lösung von gut geregelt, um Ihren Kunden umfassende Vorsorge zugänglich zu machen. Integriert sich auch in Ihren Beratungsprozess.',
    keywords: {
      pensionFoundation: 'Vorsorgestiftung',
      solution: 'Vorsorgelösung',
      trustees: 'Stiftungsrat',
      advice: 'Vorsorgeberatung',
      advisor: 'Vorsorgeberater',
    },
  },
  digital_footprint: {
    title: 'Digitaler Nachlass in der Schweiz online erstellen ',
    description:
      'In 10 min gut vorgesorgt mit einem Digitalen Nachlass. Konden dokumentieren ✓ Digitale Werte abbilden ✓ Daten bewahren✓ Bequem von zuhause ✓ Schweizer Anbieter ✓ ',
  },
  register_assets: {
    title: 'Schweizer Vermögens- & Sachregister online erstellen ',
    description:
      'In 10 min gut vorgesorgt mit einem Schweizer Vermögens- und Sachregister. Vermögenswerte erfassen ✓ Klarheit schaffen ✓ Bequem von zuhause ✓ Schweizer Anbieter ✓ ',
  },
  grosseItern: {
    title: 'Grosseltern Magazin Spezialangebot',
  },
  preventiveCheckUp: {
    title: 'Vorsorge-Check',
    description:
      'Mit dem Vorsorge-Check können Sie Ihre Vorsorgeplanung überprüfen und gegebenenfalls anpassen.',
    keywords: {
      pensionFoundation: 'Vorsorge-Check',
      solution: 'Haftungsrisiko',
      trustees: 'Stiftungsrat',
      advice: 'Vorsorgeberatung',
      advisor: 'Pensionskasse',
    },
  },
  cohabitation_agreement: {
    title: 'Schweizer Konkubinatsvertrag online erstellen',
    description:
      'In 10 min gut vorgesorgt: Schweizer Konkubinatsvertrag online erstellen mit gut geregelt.  Rechtssicher ✓ Bequem von zuhause ✓ Schweizer Anbieter ✓ ',
  },

  heirat: {
    title: 'Heirat: Diese Vorsorge ist jetzt wichtig',
    description:
      'Heiraten bedeutet, Verantwortung zu übernehmen. Für sich selbst, für Ihren Ehepartner, für Ihre Familie. Wir helfen Ihnen bei der nötigen Vorsorge.',
  },
  'geburt-eines-kindes': {
    title: 'Geburt eines Kindes: Diese Vorsorge ist jetzt wichtig',
    description:
      'Die Geburt eines Kindes ist ein wunderbares Ereignis. Jetzt ist der perfekte Zeitpunkt, die schönen Momente abzusichern und langfristig zu denken.',
  },
  immobilienkauf: {
    title: 'Kauf einer Immobilie: Diese Vorsorge ist jetzt wichtig',
    description:
      'Der Kauf eines Eigenheims ist ein grosser Schritt. Damit diese Geborgenheit auch in schwierigen Zeiten bestehen bleibt, ist es wichtig, rechtzeitig vorzusorgen.',
  },
  pensionierung: {
    title: 'Pensionierung: Diese Vorsorge ist jetzt wichtig',
    description:
      'Mit dem Eintritt ins Rentenalter beginnt ein neuer Lebensabschnitt. Gerade jetzt ist es wichtig, über die Zukunft nachzudenken.',
  },
  krankheit: {
    title: 'Diagnose einer Krankheit: Diese Vorsorge ist jetzt wichtig',
    description:
      'Eine schwere Krankheit verändert alles. Eine Patientenverfügung und ein Testament sorgen dafür, dass Ihre Wünsche respektiert und Ihre Liebsten abgesichert sind',
  },
  'tod-verwandter': {
    title: 'Todesfall: Diese Vorsorge ist jetzt wichtig',
    description:
      'Der Tod eines Verwandten ist eine schwere Zeit. Schaffen Sie Ordnung und helfen Sie Ihren Hinterbliebenen, alles in Ihrem Sinne zu verwalten.',
  },
  unternehmensgruendung: {
    title: 'Unternehmensgründung: Diese Vorsorge ist jetzt wichtig',
    description:
      'Mit der Gründung eines Unternehmens setzen Sie Ihre Vision in die Tat um und schaffen bleibenden Wert. In dieser aufregenden Zeit ist es wichtig, sich abzusichern',
  },
  scheidung: {
    title: 'Scheidung: Diese Vorsorge ist jetzt wichtig',
    description:
      'Eine Scheidung kann eine herausfordernde Zeit sein. Wir helfen Ihnen, alle notwendigen Regelungen zu treffen, damit Sie und Ihre Familie gut abgesichert sind.',
  },
};
export default metadata;
