const livingWillLanding = {
  mainBanner: {
    intro: 'Create your living will online:',
    title: 'Legally secure, done in 5 minutes',
    subtitle:
      'A living will allows you to document your consent or refusal for medical treatments in case you are unable to make decisions for yourself.',
    buttonLabel: 'Create Living Will',
  },
  youtubeTutorial: 'Why is a living will important?',
  aboutCards: {
    proactiveMeasures: {
      title: 'Proactive measures',
      description:
        'If you become unable to act, a living will ensures that your treatment aligns with your wishes.',
    },
    wishesYouCanAdapt: {
      title: 'Adaptable wishes',
      description:
        'Anyone with decision-making capacity can create a living will and change or revoke it at any time.',
    },
    legalSecurity: {
      title: 'Peace of mind for your loved ones',
      description:
        'Your documented wishes provides peace of mind to your loved ones in case of an emergency, sparing them from difficult decisions.',
    },
  },
  subBanner: {
    title: 'Well-organized with a legally compliant living will',
    subtitle:
      'With "gut geregelt," you can quickly create your living will, access legal advice on complex issues, and protect your loved ones.',
    buttonLabel: 'Create for free now',
  },
  newsletter: {
    title: 'Get organized with your living will',
    buttonLabel: 'Create now',
  },
  faq: {
    questions: {
      question1: 'What is a living will, and what is its purpose?',
      question2: 'What are the requirements for creating a living will?',
      question3: 'When does a living will come into effect?',
      question4: 'What can a living will prevent?',
      question5:
        'How do the representatives defined in the living will function?',
      question6: 'Is a copy of the living will valid for use when needed?',
      question7: 'Does my living will need to be notarized?',
      question8: 'Can someone else complete my living will for me?',
      question9:
        "What if I can't sign my living will myself (e.g., due to blindness, MS, or Parkinson's)?",
      question10: 'Is my living will valid abroad?',
      question11:
        'Can I specify a "do not resuscitate" order in my living will?',
      question12:
        "Do I need to name a representative? What if I can't or don't want to?",
      question13: 'Should I inform my representative in advance?',
      question14: 'Is my spouse automatically my representative?',
      question15: 'Can I designate my minor child as a representative?',
      question16: 'If I move or marry, should I update my living will?',
      question17:
        'Should I provide my family doctor with a copy of the living will?',
      question18: 'Is my online living will publicly accessible?',
      question19: 'What if my living will is not followed?',
      question20: 'How can I ensure its validity?',
      question21:
        'Are there other documents I should prepare besides the living will?',
      question22: 'How does "gut geregelt" assist with my living will?',
    },
    answers: {
      answer1:
        'A living will is a one-sided, written statement made when mentally competent. It provides doctors and healthcare providers with medical directives for when you are no longer able to make or communicate decisions.',
      answer2: 'Decision-making capacity regarding medical choices.',
      answer3:
        'A living will comes into effect if both incapacity and a poor prognosis occur, such as in cases of severe stroke, advanced dementia, late-stage brain tumors, coma, or a vegetative state following an accident, surgery, or resuscitation.',
      answer4:
        'Avoids prolonging natural death through medical intervention, stops resuscitation attempts, prevents life-sustaining machines without improvement prospects, and avoids artificial nutrition.',
      answer5:
        'The representatives listed in the living will are authorized in the order named to make medical decisions if an unspecified situation arises. In case of conflict, Swiss law allows close individuals to approach the Adult Protection Authority.',
      answer6:
        'Yes, a copy can be used when necessary. However, it is recommended that at least one representative knows the location of the original documents.',
      answer7:
        'No, under Swiss Adult Protection Law (effective 2013), a living will created while competent is legally binding.',
      answer8:
        'Yes, as long as you understand the content and can add the date and signature yourself, either in writing or with a qualified electronic signature.',
      answer9: 'Consider having your living will notarized.',
      answer10:
        '"gut geregelt" can only support living wills within Switzerland. Verify binding status abroad, as regulations vary.',
      answer11:
        'In emergencies, saving and stabilizing life is the priority, so time may not allow searching for a living will. However, emergency personnel are required to act based on the presumed wishes of the non-responsive individual.',
      answer12:
        "A living will is legally binding even without a named representative, though it's beneficial to update it annually in this case.",
      answer13:
        "It's practical to inform your representative so they understand their role and expectations.",
      answer14:
        'No, if desired, your spouse must be explicitly listed as a representative.',
      answer15:
        'Yes, the law requires only that a representative be capable of decision-making, not necessarily an adult. However, we recommend involving an adult as support.',
      answer16:
        'Updating your living will to reflect current circumstances is advisable, so consider changes as needed.',
      answer17: 'We recommend providing your treating physician with a copy.',
      answer18: 'No, access is password-protected.',
      answer19:
        'You or close individuals can contact the Adult Protection Authority if the living will is not followed or interests of the incapacitated person are at risk.',
      answer20:
        '"gut geregelt" ensures legal validity. Each living will must include the creation date and personal signature.',
      answer21:
        'Yes, a {{powerOfAttorney}} and {{lastWill}} are two additional documents that should be prepared as soon as possible.',
      answer22:
        'At "gut geregelt," we support you in smoothly and legally preparing your living will so that you can look forward with confidence.',
    },
  },
  hyperlinks: {
    lastWill: 'Last will',
    livingWill: 'Living will',
    powerOfAttorney: 'Power of attorney',
  },
  situations: {
    heirat: {
      content: {
        main: {
          title: 'Living will',
          description:
            'With a living will, you ensure that your medical wishes are respected in case of emergency. You decide which treatments you want to receive and which you do not.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'Health uncertainties increase with age. A living will gives you the security that your decisions will be respected even when you can no longer make them yourself.',
            ],
          },
          {
            title: 'Your benefits',
            description: [
              'Self-determination in emergencies',
              'Relief for relatives',
              'Legal security',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Learn more',
        startProcess: 'Start process',
      }
    },
    'geburt-eines-kindes': {
      content: {
        main: {
          title: 'Living will',
          description:
            'A living will specifies which medical decisions should be made on your behalf when you are no longer able to do so. This ensures your self-determination and relieves your family.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'With the birth of a child comes greater responsibility. A living will ensures that your medical wishes are respected and your family has clear instructions in difficult situations.',
            ],
          },
          {
            title: 'Your benefits',
            description: [
              'Self-determination in medical matters',
              'Relief for your relatives',
              'Protection and clear regulations for the family',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Learn more',
        startProcess: 'Start process',
      }
    },
    immobilienkauf: {
      content: {
        main: {
          title: 'Living will',
          description:
            'With a living will, you specify which medical measures should be taken or omitted in an emergency. This way, you maintain control over your medical care, even when you can no longer decide for yourself.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              "Especially with long-term commitments like home ownership, it's important to keep personal provision in mind. A living will gives you security that your medical wishes are respected and your home remains protected.",
            ],
          },
          {
            title: 'Your benefits',
            description: [
              'Ensuring your medical wishes',
              'Relief for your relatives',
              'Complement to protecting your personal and financial values',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Learn more',
        startProcess: 'Start process',
      }
    },
    pensionierung: {
      content: {
        main: {
          title: 'Living will',
          description:
            'With a living will, you ensure that your medical wishes are respected in case of emergency. You decide which treatments you want to receive and which you do not.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'Health uncertainties increase with age. A living will gives you the security that your decisions will be respected even when you can no longer make them yourself.',
            ],
          },
          {
            title: 'Your benefits',
            description: [
              'Self-determination in emergencies',
              'Relief for relatives',
              'Legal security',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Learn more',
        startProcess: 'Start process',
      }
    },
    krankheit: {
      content: {
        main: {
          title: 'Living will',
          description:
            'A living will specifies which medical measures should be taken or omitted when you can no longer decide for yourself.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'A serious illness can take unexpected turns. A living will gives you the security that your treatment wishes are respected and relieves your relatives.',
            ],
          },
          {
            title: 'Your benefits',
            description: [
              'Self-determination in medical matters',
              'Relief for relatives',
              'Clarity in difficult situations',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Learn more',
        startProcess: 'Start process',
      }
    },
    'tod-verwandter': {
      content: {
        main: {
          title: 'Living will',
          description:
            'A living will specifies which medical measures should be taken or omitted when you are no longer able to decide for yourself. You create clarity and relieve your family in difficult times.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'The loss of a close relative shows how important it is to clearly regulate your own wishes. With a living will, you ensure that your medical decisions are respected and your family is not burdened in emergency situations.',
            ],
          },
          {
            title: 'Your benefits',
            description: [
              'Ensuring your medical wishes',
              'Relief for the bereaved',
              'Clear instructions for emergencies',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Learn more',
        startProcess: 'Start process',
      }
    },
    unternehmensgruendung: {
      content: {
        main: {
          title: 'Living will',
          description:
            'A living will specifies which medical measures should be taken or omitted when you can no longer decide for yourself.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'A serious illness can take unexpected turns. A living will gives you the security that your treatment wishes are respected and relieves your relatives.',
            ],
          },
          {
            title: 'Your benefits',
            description: [
              'Self-determination in medical matters',
              'Relief for relatives',
              'Clarity in difficult situations',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Learn more',
        startProcess: 'Start process',
      }
    },
    scheidung: {
      content: {
        main: {
          title: 'Living will',
          description:
            'With a living will, you determine which medical decisions should be made when you are no longer able to do so. After a divorce, it provides you with the necessary independence and self-determination.',
        },
        columns: [
          {
            title: 'Why now',
            description: [
              'A divorce changes not only your legal but also your personal situation. A living will ensures that your medical wishes are respected independently of former family ties.',
            ],
          },
          {
            title: 'Your benefits',
            description: [
              'Independence in medical decisions',
              'Protection of your self-determination',
              'Relief for your new partner or relatives',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'Learn more',
        startProcess: 'Start process',
      }
    },
  },
};

export default livingWillLanding;
