const registerAssets = {
  mainBanner: {
    title: 'Clarté et aperçu avec le registre des biens et actifs',
    subtitle:
      "Une vue d'ensemble complète de l'ensemble de votre patrimoine vous procure non seulement la tranquillité d'esprit, mais facilite également la vie de vos proches.",
    buttonLabel: 'Gagner en clarté',
  },
  youtubeTutorial: 'Comment préparer votre succession ?',
  aboutCards: {
    logPhysicalAssets: {
      title: 'Enregistrer les actifs physiques',
      description:
        'Enregistrez les actifs physiques là où vos proches peuvent les trouver et à qui vous souhaitez les léguer.',
    },
    outlineRelationships: {
      title: 'Décrire les relations',
      description:
        'Notez vos coordonnées bancaires et autres, où vous avez investi des biens, pour faciliter les recherches de votre famille.',
    },
    simplifyCleanup: {
      title: 'Simplifier le nettoyage',
      description:
        'Conservez tout centralisé et bien protégé en un seul endroit, afin que les personnes de confiance puissent rapidement accéder aux informations pertinentes.',
    },
  },
  subBanner: {
    title:
      "La vue d'ensemble qui épargne des dizaines d'heures à votre famille",
    subtitle:
      'Beaucoup de nos clients le savent par expérience - lorsque peu de choses sont documentées et enregistrées, des heures sont gaspillées à obtenir une vue claire des biens et actifs du défunt. Nous vous aidons à épargner cette expérience à vos proches.',
    buttonLabel: 'Obtenir un aperçu',
  },
  testimonials: {
    testimonial1: {
      name: 'Elisabeth Müller',
      text: "Ma fille m'a aidée à inscrire le patrimoine de mon mari et le mien dans l'inventaire du registre des biens. C'était un soulagement pour nous tous de savoir où nous avons quoi.",
    },
    testimonial2: {
      name: 'Sascha Zürcher',
      text: "J'avais déjà un testament. Mais avec gut geregelt, j'ai maintenant aussi une vue d'ensemble de tous mes biens, que je peux mettre à jour en continu et rendre directement accessible aux personnes concernées en cas de décès.",
    },
    testimonial3: {
      name: 'Vreni Frey',
      text: "Une solution simple qui est un bon exercice pour tout noter ce qui a de la valeur. J'ai été surpris de voir combien de choses se sont accumulées auxquelles je n'avais pas pensé directement.",
    },
  },
  newsletter: {
    title: 'Votre succession, bien organisée',
    buttonLabel: 'Organiser maintenant',
  },
  faq: {
    questions: {
      question1:
        "Qu'est-ce qu'un registre des biens et actifs et pourquoi est-il important ?",
      question2:
        "Quels types d'actifs peuvent être inclus dans le registre des biens et actifs ?",
      question3: 'Comment fonctionne le service de registre des biens ?',
      question4:
        "Comment puis-je m'assurer que mes proches ont accès à mon registre des biens ?",
      question5: 'Que se passe-t-il avec mes actifs après mon décès ?',
      question6:
        'Puis-je mettre à jour ou modifier mon registre des biens et actifs ?',
      question7: 'Quelle est la sécurité de mes informations personnelles ?',
      question8:
        "Quels aspects juridiques doivent être pris en compte lors de la gestion d'un registre des biens et actifs ?",
      question9:
        'Le service peut-il aider les héritiers à clarifier les questions financières ?',
    },
    answers: {
      answer1:
        "Un registre des biens et actifs est un enregistrement complet de tous les actifs physiques et des relations avec les institutions financières d'une personne. Il est important de tenir un tel registre pour avoir une vue claire de ses actifs et faciliter le règlement pour les proches après le décès. Cela peut épargner des dizaines d'heures à vos proches.",
      answer2:
        "Le registre peut inclure une variété d'actifs, y compris des biens immobiliers, des véhicules, des bijoux, des œuvres d'art, des comptes bancaires, des comptes d'investissement et plus encore.",
      answer3:
        "Notre service offre une plateforme conviviale où les clients peuvent saisir et gérer leurs actifs. Ils peuvent définir l'emplacement, le statut de propriété et les bénéficiaires pour chaque actif.",
      answer4:
        "Vous définissez des personnes de confiance qui obtiennent un accès illimité à votre compte après votre décès. Ils l'obtiennent avec un certificat de décès et un certificat d'exécuteur testamentaire.",
      answer5:
        'Le registre des biens et actifs, avec le testament, facilite le règlement pour les proches en donnant des instructions claires sur la façon dont les actifs doivent être distribués ou gérés et où trouver quoi.',
      answer6:
        'Oui, les clients peuvent mettre à jour ou modifier leur registre des biens et actifs à tout moment pour refléter les changements dans leurs actifs ou bénéficiaires.',
      answer7:
        "Nous mettons en œuvre des mesures de sécurité strictes pour garantir la plus haute confidentialité et sécurité des informations des clients, y compris des cryptages et des contrôles d'accès conformes aux normes de l'industrie.",
      answer8:
        "Les clients doivent prendre en compte des questions juridiques telles que la rédaction d'un testament et les questions de succession. Il est conseillé de demander un avis juridique pour s'assurer que le registre est conforme aux lois en vigueur.",
      answer9:
        "Oui, en fournissant une vue claire des actifs du défunt et en facilitant l'identification et la gestion des actifs, le service aide les héritiers à clarifier les questions financières.",
    },
  },
  situations: {
    pensionierung: {
      content: {
        main: {
          title: 'Registre des biens et des actifs',
          description:
            'Un registre des biens et des actifs documente vos possessions et vos objets de valeur. Cela crée de la transparence et garantit que tout ce qui est important pour vous reste entre de bonnes mains.',
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              'Après le décès, la répartition des biens et des possessions devient souvent un fardeau. Avec un registre des actifs, vous évitez les ambiguïtés et vous vous assurez que tout est réglé selon vos souhaits.',
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              'Clarté sur les actifs et les possessions',
              'Protection contre les litiges',
              'Ordre et transparence pour votre famille',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'En savoir plus',
        startProcess: 'Démarrer le processus',
      }
    },

    'tod-verwandter': {
      content: {
        main: {
          title: 'Registre des biens et des actifs',
          description:
            'Un registre des biens et des actifs documente vos possessions et vos objets de valeur. Cela crée de la transparence et garantit que tout ce qui est important pour vous reste entre de bonnes mains.',
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              "Après le décès d'un proche, la répartition des biens et des possessions devient souvent un fardeau. Avec un registre des actifs, vous évitez les ambiguïtés et vous vous assurez que tout est réglé selon vos souhaits.",
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              'Clarté sur les actifs et les possessions',
              'Protection contre les litiges',
              'Ordre et transparence pour votre famille',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'En savoir plus',
        startProcess: 'Démarrer le processus',
      }
    },
  },
};

export default registerAssets;
