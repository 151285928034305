const en = {
  homeTitle: 'Become gut geregelt',
  homeDescription:
    'Make your personal and family provisions now or speak with a financial or legal advisor.',
  homeHighlight: 'financial',
  homeHighlight2: 'legal advisor',

  clear: 'Clear',
  search: 'Search',
  done: 'Done',

  ch: 'Switzerland',
  noData: 'No data',
  beneficiaries: 'Beneficiaries',
  representatives: 'Representatives',
  footerSettings: 'Footer',

  service: 'Service',
  accessLevel: 'Access level',
  addServiceAccess: 'Add artifact access',
  afterDeath: 'After death',
  whileAlive: 'While alive',

  customerOpinion: 'Customer opinions',
  joinWaitlist: 'Join waitlist',

  can_edit: 'Can edit',
  can_view: 'Can view',

  view: 'View',
  access: 'Access',

  civilStatus: {
    married: 'Married',
    single: 'Single',
    divorced: 'Divorced',
    widowed: 'Widowed',
    registered_partnership: 'Registered partnership',
  },

  contractParties: {
    self: 'Myself',
    spouse: 'Spouse',
  },

  fileTypeOptions: {
    audio: 'Audio',
    video: 'Video',
    document: 'Document',
    archive: 'Archive',
    image: 'Image',
  },

  guestTaxFolder: {
    title: 'Tax documents for {{user}}',
    description:
      'Here you can view all tax-relevant documents and contact your client to request missing information or clarify open questions.',
    messageClient: 'Message to the client',
  },

  taxFolder: {
    title: 'Overview of your taxes',
    requestTaxExpert: 'Request tax expert',
    submitLatestTax: 'Submit latest tax',
    description:
      'Check previous versions and make sure you know how things were done back then.',
    findTaxExpert: {
      title: 'Find a tax expert near you',
      description:
        'Various organizations and experts assist you in preparing and optimizing your tax return. Find the right partner for you.',
      tableHead: {
        expert: 'Expert',
        location: 'Location',
        price: 'Price',
      },
    },
  },

  chat: {
    customerChat: 'Customer chats',
  },

  dashCardInfos: {
    livingWill: {
      title: 'Living will',
      description: 'Complete your living will in less than 7 minutes.',
    },
    powerOfAttorney: {
      title: 'Power of attorney',
      description: 'We assume this will take 5 minutes.',
    },
    lastWill: {
      title: 'Last will',
      description: '80% of people complete their last will within 10 minutes.',
    },
    marriageContract: {
      title: 'Marriage contract',
      description: 'Put your spouse in a better financial position.',
    },
    digitalFootprint: {
      title: 'Digital footprint',
      description: 'Leave a clean digital legacy.',
    },
    assetsRegister: {
      title: 'Register of assets',
      description: 'Digital representation of your assets.',
    },
    cohabitationAgreement: {
      title: 'Cohabitation agreement',
      description: 'Organize your life together',
    },
    successionPlanning: {
      title: 'Succession planning',
      description: 'Own a business?! Ensure its sustainability.',
    },
    taxFolder: {
      title: 'Tax folder',
      description:
        'Intelligent and digital tax folder for all your tax documents.',
    },
    comingSoon: {
      title: 'Coming soon',
      description: 'gut geregelt is constantly working on new services.',
    },
    continue: 'Continue',
    getStarted: 'Get started',
    summaryAndReview: 'View document',
    uploadDocument: 'Upload document',
    update: 'Update',
    history: 'History',
    markAsDone: 'Mark as done',
    startOver: 'Start over',
    viewData: 'View data',
    guest: {
      disabledButton:
        'The host has added additional restrictions for accessing this service.',
      getDocButton: 'Retrieve document',
      noDocumentUploaded: '{{hostName}} has not yet uploaded {{documentType}}.',
    },
  },

  loginInfo: {
    title: 'Do your loved ones a favor, be well organized',
    title1: 'Hello, welcome back',
    newUser: 'New user?',
    createAccount: 'Create account',
    forgotPassword: 'Forgot password?',
    login: 'Login',
    resetPasswordInfo:
      'Please enter the email address associated with your account and we will email you a link to reset your password.',
    sendRequest: 'Send request',
    returnToLogin: 'Return to login',
    setNewPassword: 'Set new password',
    updatePassword: 'Update password',
    invalidLink: 'Link is invalid or expired!',
    returnToResetPassword: 'Return to reset password',
    welcomeOnboard: 'Welcome onboard',
    welcomeOnboardDescription:
      'To proceed to the Gutgeregelt dashboard, please set a new password.',
  },

  services: {
    forIndividuals: 'For individuals',
    forCompanies: 'For companies',
    gutGeregelt: 'gut geregelt',
    livingWill: 'Living will',
    powerOfAttorney: 'Power of attorney',
    lastWill: 'Last will',
    marriageContract: 'Marriage contract',
    digitalFootprint: 'Digital legacy',
    registerAssets: 'Register of assets',
    cohabitationAgreement: 'Cohabitation agreement',
    taxFolder: 'Tax folder',
    pricing: 'Pricing',
    login: 'Login',
    makeProvisions: 'Register now',
    pensionCheck: 'Pension check',
    partnerWithUs: 'Whitelabel partner',
    blog: 'Blog',
    dashboard: 'Dashboard',
    consulting: 'Consulting',
    aboutUs: 'About us',
    other: 'Other',
  },

  settings: {
    changeAccount: 'Change account',
    changeAccountContent: {
      title: 'Switch to another account',
    },
    settings: 'Settings',
    needHelp: 'Need help?',
    logOut: 'Log out',
  },

  roles: {
    executor: 'Executor',
    lawyer: 'Lawyer',
    trustee: 'Trustee',
    familyMember: 'Family member',
    friend: 'Friend',
  },

  fileSystemTableHead: {
    name: 'Name',
    size: 'Size',
    type: 'Type',
    modifiedAt: 'Modified',
  },

  learnMore: 'Learn more',
  startProcess: 'Start now',
  selected: 'Selected',
  folder: 'Folder',
  file: 'File',
  folders: 'Folders',
  files: 'Files',
  create: 'Create',
  upload: 'Upload',
  uploading: 'Uploading...',
  folderName: 'Folder name',
  fileName: 'File name',
  endDateError: 'The end date must be after the start date',
  newFolder: 'New folder',
  editFolder: 'Edit folder',
  uploadFiles: 'Upload files',
  editFile: 'Edit file',
  disabledService: {
    title: 'This service is not available in your current subscription plan',
    description: 'Please upgrade your plan to unlock this feature',
  },
  editAccessRights: 'Edit access rights',
  editAccessRightsDscp:
    'Specify which data the institution is allowed to view. You can adjust this at any time or completely revoke access.',
  shareSelected: 'Share selected data only',
  acceptingInvitation: 'Accept invitation',
  acceptingInvitationFail: 'Invitation expired or invalid...',
  removeAll: 'Remove all',
  account: 'Account',
  main: 'Main',
  guest: 'Guest',
  manage: 'Manage',
  trackHistoryTitle:
    'Below you can track uploaded documents and the history of {{documentType}}',
  getStarted: 'Get started',
  resetToDefault: 'Reset to default',
  lastUpdated: 'Last updated:',
  description: 'Description',
  imageHelper: 'Allowed file formats: *.jpeg, *.jpg, *.png, *.gif',
  imageMaxSize: 'Maximum file size:',
  storageLocation: 'Storage location',
  hire: 'Hire tax expert',
  beneficiary: 'Beneficiary',
  continue: 'Continue',
  beneficiarie: 'Beneficiary for the benefit of',
  removeFilter: 'Remove filter',
  addNewBeneficiary: 'Add beneficiary',
  primary: 'Primary contact',
  representative: 'Representative',
  addNewAsset: 'Add new asset',
  addNewProperty: 'Add new property',
  uploadNew: 'Upload new',
  apply: 'Apply',
  addMore: 'Add more',
  saveDetails: 'Save details',
  saving: 'Saving...',
  enoughForNow: 'Pause, please!',
  finishForToday: 'That’s enough for today',
  currentPackage: 'You have selected the following provision package:',
  nextPaymentDue: 'Next payment due on',
  expiresAt: 'Expires on',
  freePackage: 'Free package',
  userDataAccess: {
    request: 'Request data access',
    awaiting: 'Waiting for access approval',
    approved: 'Display customer data',
  },
  info: 'Info',
  shareWith: 'Share with',
  starterPackage: 'Starter package',
  standardPackage: 'Standard package',
  familyPack: 'Family package',
  noPackage: 'No package',
  needHelp: 'Need help?',
  continueToLastWill: 'Continue with the last will',
  continueToPOA: 'Continue with power of attorney',
  addAnother: 'Add trusted person',
  add: 'Add',
  finish: 'Finish',
  delete: 'Delete',
  edit: 'Edit',
  save: 'Save',
  close: 'Close',
  next: 'Next',
  back: 'Back',
  or: 'or',
  role: 'Role',
  current: 'Current',
  gender: 'Gender',
  male: 'Male',
  female: 'Female',
  howMany: 'How many',
  cancel: 'Cancel',
  priority: 'Priority',
  areYouSureYouWantToLeave: 'Are you sure you want to take a break?',
  enoughForNowDscp:
    'Don’t worry, we save your entries and regularly remind you that this document is not yet legally completed. You can continue at any time where you left off.',
  enoughForNowCancel: 'Yes, I want a break',
  enoughForNowContinue: 'No, thank you',
  addPropertyOrAsset: 'Add property or asset',
  addItem: 'Add asset',
  good: 'Asset',
  addNew: 'Add new',
  owner: 'Owner',
  adress: 'Address',
  download: 'Download',
  upgradePlan: 'Upgrade your plan to download two or more documents',
  choosePlan: 'Choose a plan',
  switzerland: 'Switzerland',
  fillInfoTitle:
    'Please ensure you provide important information for your main account.',
  switch: 'Switch',
  activatePersonalAccount: 'Activate your personal account',
  howManyMembers: 'How many family members?',
  requiredBasicInfoTitle:
    'Please provide the required basic information before proceeding',
  allType: 'All types',
  selectDate: 'Select date',
  selectDateRange: 'Select date range',
  familyPackage: {
    title: 'My Family',
    description:
      'With the family package, you and your family members can arrange your provisions at a reduced price.',
    helperText:
      'Please fill in the information of your family members carefully. This information cannot be updated once the user has accepted the invitation.',
  },
  deleteConfirmation: {
    title: 'Confirm deletion',
    oneItemQuestion: 'Do you really want to delete this file?',
    multipleItemsQuestion:
      'Do you really want to delete these {{itemNr}} files?',
    question: 'Are you sure you want to delete {{item}}?',
    multiDeleteQuestion: 'Do you really want to delete {{items}} items?',
    cancelDelete: 'No, keep {{item}}',
    confirmDelete: 'Yes, delete {{item}}',
  },

  formLabels: {
    tag: 'Tag',
    name: 'Name',
    firstName: 'First name',
    lastName: 'Last name',
    civilStatus: 'Civil status',
    place_of_citinzenship: 'Place of citizenship',
    place_of_marriage: 'Place of marriage',
    date_of_marriage: 'Date of marriage',
    dropOrSelectFile: 'Drop or select file',
    children_count: 'Number of children',
    street: 'Street',
    houseNumber: 'Number',
    postalCode: 'Postal code',
    city: 'City',
    country: 'Country',
    uploadPhoto: 'Upload photo',
    twoFactorAuth: 'Two-factor authentication',
    verify: 'Verify',
    uploadLogo: 'Upload logo',
    favicon: 'Favicon',
    shareForFemalePartner: 'Share for your female partner (in %)',
    shareForMalePartner: 'Share for your male partner (in %)',
    submit: 'Submit',
    invite: 'Invite',
    emailAddress: 'Email address',
    role: 'Role',
    twoFactorAuthHelp:
      'If you activate 2FA, a code will be sent to your phone that you need to enter to log in.',
    region: 'Region',
    artifact: 'Artifact',
    search: 'Search',
    mobilePhone: 'Mobile phone',
    hide_header_links: 'Hide header links',
    hide_solution_menu: 'Remove "Solutions" column from the footer',
    footer_title: 'Footer title',
    footer_text: 'Footer text',
    description: 'Description',
    storageLocation: 'Storage location',
    designatedBeneficiary: 'Designated beneficiary',
    benefiterAfterSeparation:
      'In the event of a separation, the property will become the sole property of',
    action: 'Action',
    password: 'Password',
    oldPassword: 'Old password',
    newPassword: 'New password',
    confirmPassword: 'Confirm password',
    minimum6Characters: 'Minimum 6 characters',
    primaryRepresentative: 'Primary representative',
    secondaryRepresentative: 'Secondary representative',
    howManyChildren: 'How many children do you have?',
    assetType: 'Asset type',
    date_of_birth: 'Date of birth',
    rep_inform_date: 'Information date',
    trusted_person: 'Executor',
    billing_email: 'Billing email',
    asset: 'Asset',
    newConsultant: 'New consultant',
    institutionName: 'Financial institution',
    message: 'Message',
    location: 'Location',
    topics: 'Topics',
    type: 'Type',
    plusTopic: '+ Topic',
    propertyType: 'Property type',
    disposal_fixed_amount: 'Fixed amount',
    amount: 'Amount',
    passportLocation: 'Passport storage location',
    date_of_first_payment: 'Date of first payment',
    meet_year: 'Year',
    date_of_moving_in: 'Date of moving in',
    idCardLocation: 'ID card storage location',
    residencePermitLocation: 'Residence permit storage location',
    familyBookLocation: 'Family book storage location',
    birthCertificateLocation: 'Birth certificate storage location',
    disposal_percentage: 'Percentage',
    joint_household_account_bank: 'Joint household account bank',
    plusUser: '+ User',
    domain: 'Domain',
    addWidget: 'Add widget',
    plusWidget: '+ Widget',
    organisation_name: 'Organisation name',
    primary_color: 'Primary color',
    secondary_color: 'Secondary color',
    sendAutomatically: 'Share automatically when I update the living will',
    deathReport: 'Death report',
    executorCertificate: 'Executor certificate',
    insuranceCompany: 'Insurance company',
    insurancePolicyNumber: 'Policy number',
    ahvFund: 'AHV fund',
    ahvNumber: 'AHV number',
    pensionFundOrganization: 'Pension fund organization',
    financialInstitution: 'Financial institution',
    trustedPerson: 'Executor',
    select: 'Select',
    cadenceSelect: 'Select cadence',
    expenses_to_be_covered: 'Expenses to be covered',
    workload: 'Workload (in %)',
    shareYouPay: 'Share you pay (in %)',
    afterDeath: 'After death',
    access: 'Access',
    executor: 'Executor',
    trustees_list: {
      husband_wife: 'Husband, Wife',
      partner: 'Partner',
      'close-friend': 'Close friend',
      'brother-sister': 'Brother, Sister',
      'brother-sister-in-law': 'Brother-in-law, Sister-in-law',
    },
    beneficiarieCategories: {
      selectCategory: 'Select category',
      individual: 'Individual person',
      company: 'Company',
      foundation: 'Foundation',
      association: 'Association',
    },
    ordinalNumbers: {
      first: 'first',
      second: 'second',
      third: 'third',
      fourth: 'fourth',
      fifth: 'fifth',
      sixth: 'sixth',
      seventh: 'seventh',
      eighth: 'eighth',
      ninth: 'ninth',
      tenth: 'tenth',
      information: 'Information about the {{ordinalNumber}} child',
    },
    good_description: 'Description of the good',
    approximate_value: 'Approximate value',
    storage_location: 'Storage location',
    previous_owner: 'Previous owner',
    font_settings: 'Font settings',
    title_font: 'Title font',
    subtitle_font: 'Subtitle font',
    label_font: 'Label font',
    prose_font: 'Text font',
    link_font: 'Link font',
  },

  userSettings: {
    general: 'General',
    billing: 'Billing',
    my_family: 'My family',
    successors: 'Trusted persons',
    notifications: 'Notifications',
    dataSharing: 'Data sharing',
    changePassword: 'Change password',
    changeReminder:
      'Change the frequency of reminders when a process is interrupted',
    reminder: 'Reminder',
    reminderFrequency: 'Reminder frequency',
    frequencyChoices: {
      weekly: 'Weekly',
      monthly: 'Monthly',
      quarterly: 'Quarterly',
      disabled: 'Disabled',
    },
    successor: {
      title: 'Define trusted persons',
      description:
        'To ensure your documents and information are available in the event of your death, you should make sure that at least two people have access to gut geregelt.',
      pleaseConfirm:
        'Please confirm that the following people will receive guest access from today (with limited access - see what is included) and will have full access to all data in the event of your death.',
      agree: 'Yes, I agree',
      addSuccessor: 'Add trusted persons',
      access: {
        select_label: 'General access right',
        switch_label: 'Precautionary artifact specific access rights',
        modal: {
          title: 'Define individual access permissions',
        },
      },
    },

    dataSharingPage: {
      noSharingTitle: 'Share data',
      toggleLabel: 'I want to share my data',
      currentlySharing: 'Data access for {{partnerName}}',
      partnerListLabel: 'Partner organisation',
      changedOrganisation: 'Organisation changed?',
      info: 'If you have activated data sharing with a partner organisation of gut geregelt, you agree to share the following data with your bank advisor:',
      sharingName: 'Name',
      sharingEmail: 'Email',
      sharingLogin: 'Last login',
      sharingServiceProgress:
        'Percentage progress of the individual provision documents (however, no actual documents or information are shared)',
    },
  },

  adminSettings: {
    general: 'General',
    team: 'Team',
    consulting: 'Consulting',
    changePassword: 'Change password',
  },

  team: {
    tableHeads: {
      name: 'Name',
      email: 'Email',
      role: 'Role',
      last_login: 'Last login',
    },
    tagChoices: {
      general: 'General',
      pension: 'Pension',
      mortgage: 'Mortgage',
      asset_management: 'Asset management',
      tax: 'Tax',
    },
    resendInvitation: 'Resend invitation',
    cancelInvitation: 'Cancel invitation',
    consultant: 'Consultant',
    consultantDescription: 'Access to the gut geregelt consultant cockpit.',
    admin: 'Admin',
    adminDescription:
      'Management of the gut geregelt cockpit, people and other settings.',
  },

  footer: {
    allRightsReserved: 'All rights reserved',
    provisionLinks: {
      title: 'Solutions',
      pricing: 'Pricing',
      livingWill: 'Living will',
      powerOfAttorney: 'Power of attorney',
      lastWill: 'Last will',
      digitalFootprint: 'Digital legacy',
      registerAssets: 'Register of assets',
    },
    resourcesLinks: {
      title: 'Resources',
      imprint: 'Imprint',
      termsAndConditions: 'Terms and conditions',
      privacyPolicy: 'Privacy policy',
      contact: 'Contact',
    },
    contact: 'Contact',
  },

  contactUs: {
    getLegalSupport: 'Get legal support',
    partnerTitle: 'Contact the gut geregelt team',
    description:
      'gut geregelt will gladly connect you with experienced legal advisors in your region if required.',
    pleaseWriteMessage: 'Please write us a message',
  },

  civil_statusWarning: {
    title: 'Are you sure you want to update your civil status?',
    description:
      'If you change your civil status, all your will data will be deleted and you will have to start over.',
    cancel: 'Cancel',
    update: 'Update',
  },

  hyperlinks: {
    lastWill: 'Last will',
    livingWill: 'Living will',
    powerOfAttorney: 'Power of attorney',
  },
  signedDoc: {
    placeholder: {
      headingText: 'Upload after signing',
      currentlySigned: 'Currently {{grammarFix}} {{docType}}',
      grammarFix: {
        livingWill: 'signed and active',
        powerOfAttorney: 'signed and active',
        lastWill: 'signed and active',
        marriageContract: 'signed and active',
        cohabitationAgreement: 'signed and active',
      },
      doctype: {
        lastWill: 'last will',
        livingWill: 'living will',
        powerOfAttorney: 'power of attorney',
        marriageContract: 'marriage contract',
        cohabitationAgreement: 'cohabitation agreement',
      },
      helper: {
        dropFilesHere: 'Drop file here or browse your device',
        browse: 'browse',
        thoroughYourDevice: 'through your device',
      },
    },
    reUpload: 'Drag and drop or click to re-upload',
    updatedAt: 'Updated at {{date}}',
  },
  shareDocument: {
    button: 'Share with doctor',
    title: 'Share it with your family doctor',
    description:
      'We recommend that you share the signed version of your living will with your family doctor.',
    doAutomatically: 'Share automatically when I update the living will',
  },
  finfinder: {
    title: 'Choose your financial advisor on FinFinder',
    button: 'Arrange a free initial consultation',
  },

  consulting: {
    title: 'Consulting',
    description: 'Support your clients with their retirement planning.',
    dashCardInfos: {
      livingWill: {
        title: 'Living will',
        description:
          'Regulates which medical treatments the client consents to or refuses if they can no longer decide for themselves.',
      },
      powerOfAttorney: {
        title: 'Power of attorney',
        description:
          'Defines who legally represents the client in the worst case, takes care of their rights and obligations towards the family and manages their financial affairs.',
      },
      lastWill: {
        title: 'Last will',
        description:
          "Defines the client's wishes regarding the inheritance of assets.",
      },
      ageAppropriate: {
        title: 'Age-appropriate 3a investing',
        description:
          'Check to what extent your client invests their 3a assets in an age-appropriate manner and take appropriate measures if necessary.',
      },
      ahvCalculator: {
        title: 'AHV calculator',
        description:
          'The AHV calculator is a pension estimate and is based on a simplified calculation method that is not binding.',
      },
      lifeExpectancy: {
        title: 'Life expectancy',
        description:
          "Calculates the client's theoretical life expectancy based on actuarial tables.",
      },
      getStarted: 'Get started',
      unlock: 'Unlock',
      createUser: 'Create user',
      createUserDescription:
        'Create a user for your client, which they can continue to use independently of you to view documents and make adjustments.',
    },
    pageTitle: 'Create client login',
    createUserForm: {
      description:
        'Your client will receive a separate email with the login details. Ask your client to change the communicated password when logging in for the first time.',
    },
  },
  consultantDashboard: {
    pageName: 'Consultant dashboard',
    title: 'Client overview',
    description:
      'Get an overview of your client portfolio and their status regarding retirement planning with gut geregelt.',
  },

  teamRoles: {
    owner: 'Owner',
    consultant: 'Consultant',
    admin: 'Admin',
  },

  financialAdvisorsModal: {
    title: 'Speak with a financial advisor near you',
    description:
      'To provide you with easy access to independent financial experts in your area, we work with FinFinder.',
  },
  legalAdvisorsModal: {
    title: 'Speak with a notary near you',
    description:
      'To provide you with easy access to legal experts in your area, we work with GetYouLawyer.',
  },

  complexFormModal: {
    title: 'We recommend that you speak to a notary',
    description:
      'Your case is more complex and therefore we strongly recommend that you speak to a legal expert to clarify the situation and develop the best solution for your case.',
    searchLabel: 'Search for notary',
    financialSearchLabel: 'Search financial advisor',
    continueLabel: 'Continue',
    requestConsultation: 'Request consultation',
  },

  financialConsultingTopics: {
    financialPlanning: 'Financial planning',
    pensionPlanning: 'Pension planning',
    taxPlanning: 'Tax advice',
    realEstate: 'Real estate advice',
    successionPlanning: 'Succession advice',
  },

  legalConsultingTopics: {
    livingWill: 'Living will',
    powerOfAttorney: 'Power of attorney',
    lastWill: 'Last will',
    marriageContract: 'Marriage contract',
  },

  markAsDoneView: {
    intro: 'Your effective {{service}}',
    title: 'Deposit your legally valid {{service}}',
    helperOne:
      'gut geregelt informs you about changes in the legal basis and also provides impulses from time to time so that the applicable legal document still meets your needs and wishes.',
    helperTwo:
      'Should you wish a different arrangement at a later point in time, you can update the legal document at any time and settle your affairs via "gut geregelt".',
    service: {
      livingWill: 'Living will',
      powerOfAttorney: 'Power of attorney',
      lastWill: 'Last will',
      marriageContract: 'Marriage contract',
      cohabitationAgreement: 'Cohabitation agreement',
    },
  },

  otp: {
    pageTitle: 'Email verification',
    title: 'Confirmation code sent to your email',
    subtitle:
      'We have sent you a 6-digit confirmation code by email to {{email}}. Please enter the code in the field below to confirm your email.',
    dontHaveCode: 'Did not receive a code?',
    resendCode: 'Resend code',
    returnToLogin: 'Return to login',
    waitforResend: 'Please wait before requesting the code again.',
  },
};

export default en;
