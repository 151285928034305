const financialPlanning = {
  situations: {
    pensionierung: {
      content: {
        main: {
          title: 'Conseil sur la situation financière',
          description:
            'Au moment de la retraite, la situation financière change souvent. Nos conseils vous aident à planifier vos finances de manière optimale afin que vous soyez également en sécurité financière à un âge avancé.',
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              'Une bonne planification financière vous apporte une sécurité à la retraite et vous permet de profiter de votre nouvelle phase de vie en toute sérénité.',
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              'Utilisation optimale de vos revenus de retraite',
              'Avantages fiscaux',
              'Sécurité financière à long terme',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'En savoir plus',
        startProcess: 'Démarrer le processus',
      }
    },

    scheidung: {
      content: {
        main: {
          title: 'Planification financière',
          description:
            'Une planification financière complète vous aide à réorganiser votre situation financière après le divorce et à la sécuriser à long terme. Vous gardez ainsi le contrôle de vos finances.',
        },
        columns: [
          {
            title: 'Pourquoi maintenant',
            description: [
              'Après un divorce, les priorités financières doivent souvent être redéfinies. Une planification financière professionnelle vous apporte clarté et sécurité pour les années à venir.',
            ],
          },
          {
            title: 'Vos avantages',
            description: [
              'Réorganisation de votre situation financière',
              'Planification pour une stabilité financière à long terme',
              'Optimisation fiscale après le divorce',
            ],
          },
        ],
      },
      actions: {
        learnMore: 'En savoir plus',
        startProcess: 'Démarrer le processus',
      }
    },
  },
};

export default financialPlanning;
