const adminDashboard = {
  main: {
    title: 'Dashboard',
    description:
      'See how many of your customers visit gut geregelt, create a login, and then also purchase a package.',
  },
  analytics: {
    cards: {
      visitors: 'Visitors',
      conversion_rate_login: 'Conversion rate to login',
      loginsCreated: 'Logins created',
      conversion_rate_pay: 'Conversion rate to paying customers',
      paying_customers: 'Paying customers',
    },
  },
  charts: {
    visitors: 'Visitors',
    conversionRateToLogin: 'Conversion rate to login',
    loginsCreated: 'Logins created',
    conversionRateToPaying: 'Conversion rate to paying customers',
    subscriptionsCreated: 'Paying customers',
  },
  historicalData: 'Historical data',
  historicalDataSubheader:
    '({{subscription_growth_percentage}}%) compared to previous year',
  demographics: 'Demographics',
  women: 'Women',
  men: 'Men',
  visits: 'Visits',

  widgetsOptions: {
    lifeExpectancy: 'Life Expectancy',
    ageAppropriate: 'Age-Appropriate 3a Investments',
    ahvCalculator: 'AHV Calculator',
  },

  clientOverview: 'Client overview',
  generalOverview: 'Dashboard',
  legalAdvisors: 'Legal advisors',
  financialAdvisors: 'Financial advisors',
  platform: 'Platform',
  user: 'User',
  mainInfoModal: {
    title: 'Please fill in missing information',
    description: 'Please add your first and last name to complete the login.',
  },

  consulting: {
    unlockWidget: {
      title: 'Unlock widget',
      description:
        'Request a quote and activate widgets for you and your advisors to offer simplified retirement planning to clients during consultations.',
    },
  },
  teamTable: {
    tableHeads: {
      name: 'Name',
      email: 'Email',
      role: 'Role',
      last_login: 'Last login',
      tag: 'Tag',
    },
  },
  consultantTable: {
    defaultSwitchTooltip:
      'Switching to the default list shows only the advisors provided by gut geregelt.',
    tableHeads: {
      name: 'Name',
      email: 'Email',
      location: 'Location',
      added: 'Added',
    },
  },
  usersTable: {
    toolbarButtons: {
      columns: 'Columns',
      export: 'Export',
      filter: 'Filter',
      transferSelectedUsers: 'Transfer selected users ({{selectedUsers}})',
      removeFromConsulting: 'Remove from consulting',
    },
    tableHeads: {
      category: 'Category',
      name: 'Name',
      email: 'Email',
      owned_by: 'Advisor',
      last_login: 'Last login',
      pension_provision: 'Pension provision',
      living_will: 'Living will',
      power_of_attorney: 'Power of attorney',
      last_will: 'Last will',
    },
  },
  deleteUser: 'Delete user',
  deleteConsultant: {
    title:
      'You are about to remove {{consultant}} from the list. Are you sure?',
    description: 'Please confirm the deletion.',
    onConfirm: 'Delete',
    onClose: 'Cancel',
  },
  deleteTeamMember: {
    title: 'You are about to delete the list. Are you sure?',
    description: 'Please confirm the deletion.',
    onConfirm: 'Delete',
    onClose: 'Cancel',
  },
  cancelInvitation: {
    title: 'You are about to cancel the invitation. Are you sure?',
    description: 'You can still re-invite the member even after cancellation.',
    onConfirm: 'Delete',
    onClose: 'Cancel',
  },
  deleteUserModal: {
    title: 'You are about to remove {{user}} from the list. Are you sure?',
    description: 'Please confirm the deletion.',
    onConfirm: 'Delete',
    onClose: 'Cancel',
  },

  userTransferModal: {
    title: 'You have selected the users below to transfer to another advisor',
    description:
      'Choose the advisor from the list below to whom they should be transferred',
  },

  confirmUnassignment: {
    title: 'Confirm the removal of assigned advisor for the selected users?',
    description: 'You can assign them to another advisor later.',
    onConfirm: 'Confirm',
    onClose: 'Cancel',
  },
};

export default adminDashboard;
