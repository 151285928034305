const digitalFootprintLanding = {
  mainBanner: {
    title:
      'Regeln und sichern Sie Ihren digitalen Nachlass in einem sicheren Tresor',
    subtitle:
      'Stellen Sie sicher, dass alle Ihre elektronischen Daten oder Vermögenswerte, Geräte und Versicherungen gesichert sind und im Falle Ihres Ablebens autorisierten Personen zugänglich gemacht werden können.',
    buttonLabel: 'Nachlass sichern',
  },
  youtubeTutorial: 'Wie Sie Ihr digitales Erbe bewahren?',
  aboutCards: {
    documentAccounts: {
      title: 'Ihre Konten dokumentieren',
      description:
        'Übertragen Sie ganz einfach alle Konten aus Ihrem Passwortmanager in gut geregelt. Keine Sorge. Wir wollen ausdrücklich keine Passwörter!',
    },
    connectDigitalValues: {
      title: 'Digitale Werte abbilden',
      description:
        'Listen Sie alle Ihre digitalen Wallets, Domains und andere Werte auf und beschreiben Sie, wo und wie Ihre Familie die Passwörter finden kann, um Zugang zu erhalten.',
    },
    preserveDigitalData: {
      title: 'Digitale Daten bewahren',
      description:
        'Organisieren und bewahren Sie Daten wie Bilder oder Videos, Musik, wichtige Dokumente und andere Daten in einem sicheren Tresor auf.',
    },
  },
  subBanner: {
    title:
      'Stellen Sie sicher, dass Sie alles sauber und ordentlich hinterlassen',
    subtitle:
      'Sofern nicht ausdrücklich in Ihrem letzten Willen oder Testamentsvertrag festgelegt, gehen Ihre digitalen Vermögenswerte im Falle Ihres Todes an Ihre gesetzlichen Erben über. Sie sollten daher zu Lebzeiten entscheiden, was nach Ihrem Tod gelöscht oder übertragen werden soll.',
    buttonLabel: 'Digitalen Fussabdruck regeln',
  },
  testimonials: {
    testimonial1: {
      name: 'Patrick Zesiger',
      text: 'Der digitale Nachlass von gut geregelt hat mir geholfen, meine digitalen Angelegenheiten in bester Ordnung zu halten. Ein absolutes Muss für jeden, der Dinge in der digitalen Welt macht!',
    },
    testimonial2: {
      name: 'Sara Becker',
      text: 'Die Gewissheit, dass sich meine Familie nicht um das Aufräumen kümmern muss und dass alle meine Social-Media-Kanäle geschlossen werden können, gibt mir ein gutes Gefühl. Super einfach einzurichten. Kann es nur weiter empfehlen.',
    },
    testimonial3: {
      name: 'Luisa Stauffer',
      text: 'Ich habe kürzlich den digitalen Nachlass mit und für meinen Vater und meine Mutter eingerichtet. Es ging sehr schnell und unkompliziert. Selbst mein Vater war froh, dass wir das endlich hinter uns gebracht hatten.',
    },
  },
  newsletter: {
    title: 'Werden Sie im digitalen Nachlass gut geregelt',
    buttonLabel: 'Jetzt organisieren',
  },
  faq: {
    questions: {
      question1: 'Was ist ein digitaler Nachlass und warum ist er wichtig?',
      question2:
        'Welche Arten von digitalen Vermögenswerten können in das digitale Erbe einbezogen werden?',
      question3: 'Wie funktioniert der Dienst für digitale Nachlässe?',
      question4:
        'Wie kann ich sicherstellen, dass meine Angehörigen Zugang zu meinem digitalen Erbe haben?',
      question5:
        'Was passiert mit meinen digitalen Vermögenswerten, wenn ich sterbe?',
      question6:
        'Wie sicher sind meine persönlichen Informationen bei Ihrem Dienst?',
      question7: 'Kann ich mein digitales Erbe aktualisieren oder ändern?',
      question8:
        'Welche rechtlichen Aspekte sollten bei der Verwaltung eines digitalen Erbes berücksichtigt werden?',
      question9:
        'Was passiert mit meinen Online-Abonnements und Konten nach meinem Tod?',
      question10:
        'Wie unterstützt Ihr Dienst die Verwaltung von Versicherungs- und Bankdokumenten?',
      question11: 'Speichert gut geregelt Passwörter oder andere Zugangsdaten?',
    },
    answers: {
      answer1:
        'Der digitale Nachlass umfasst alle digitalen Vermögenswerte und online gespeicherten Informationen einer Person. Dies kann von sozialen Medienkonten bis zu Online-Abonnements bis hin zu Kryptowährungs-Wallets reichen. Es ist wichtig, den digitalen Nachlass zu verwalten, um sicherzustellen, dass Ihre digitalen Angelegenheiten nach Ihrem Tod ordnungsgemäss gehandhabt werden und um Ihren Angehörigen den Zugang zu wichtigen Informationen zu erleichtern.',
      answer2:
        'Unser Dienst ermöglicht es Ihnen, eine Vielzahl von digitalen Vermögenswerten zu erfassen, einschliesslich Social-Media-Konten, Online-Abonnements, Krypto-Wallets, Domains, Passwortmanagern, elektronischen Geräten und vielem mehr. Sie können alle relevanten digitalen Vermögenswerte dokumentieren und Anweisungen hinterlassen, was nach Ihrem Tod mit ihnen geschehen soll und wie Zugang erlangt werden kann.',
      answer3:
        'Unser Dienst bietet Ihnen eine benutzerfreundliche Plattform, auf der Sie Ihre digitalen Vermögenswerte erfassen, verwalten und Anweisungen für die Verwaltung Ihrer digitalen Vermögenswerte nach Ihrem Tod festlegen können. Sie können festlegen, wer Zugang zu welchen Informationen haben soll und definieren, was mit Ihren digitalen Vermögenswerten geschehen soll.',
      answer4:
        'Mit unserem Dienst können Sie bestimmte Personen benennen, die Zugang zu Ihrem digitalen Erbe haben werden. Sie können auch detaillierte Anweisungen hinterlassen, wie Ihre digitalen Vermögenswerte verwaltet werden sollen, um sicherzustellen, dass Ihre Angehörigen Zugang zu wichtigen Informationen haben.',
      answer5:
        'Nach Ihrem Tod erhalten die von Ihnen benannten Personen Zugang zu Ihrem gut geregelt Konto, indem sie die Sterbeurkunde und die Vollmacht des Testamentsvollstreckers vorlegen. Damit stellen Sie sicher, dass sie Ihre digitalen Vermögenswerte gemäss Ihren Anweisungen verwalten können. Sie können festlegen, ob bestimmte Konten gelöscht, fortgeführt oder bestimmten Personen übertragen werden sollen. Unser Dienst stellt sicher, dass Ihre digitalen Angelegenheiten gemäss Ihren Wünschen gehandhabt werden.',
      answer6:
        'Wir nehmen die Sicherheit Ihrer persönlichen Informationen sehr ernst. Unser Dienst verwendet branchenübliche Sicherheitsmassnahmen zum Schutz Ihrer Daten, einschliesslich Verschlüsselungstechnologien und Zugriffsbeschränkungen. Zusätzlich speichern wir keine sensiblen Daten wie Passwörter, Kontostände und dergleichen.',
      answer7:
        'Ja, Sie können Ihr digitales Erbe jederzeit aktualisieren oder ändern. Unsere Plattform ermöglicht es Ihnen, neue Vermögenswerte hinzuzufügen, vorhandene Informationen zu löschen oder zu bearbeiten und neue Anweisungen zu hinterlassen.',
      answer8:
        'Bei der Verwaltung eines digitalen Erbes sollten rechtliche Aspekte wie die Erstellung eines Testaments und die Bestimmung der Erben berücksichtigt werden. Es ist ratsam, rechtlichen Rat einzuholen, um sicherzustellen, dass Ihre digitalen Angelegenheiten gemäss den geltenden Gesetzen gehandhabt werden.',
      answer9:
        'Nach Ihrem Tod können Ihre Online-Abonnements und Konten gemäss Ihren Anweisungen verwaltet werden. Sie können festlegen, ob bestimmte Abonnements gekündigt, Konten gelöscht oder an bestimmte Personen übertragen werden sollen.',
      answer10:
        'Unser Dienst bietet Funktionen zur Organisation Ihrer Versicherungs- und Bankbeziehungen. Sie können angeben, wo welche Dokumente zu finden sind. Wir unterstützen auch bei der automatischen Erstellung von Kündigungsschreiben für Versicherungspolicen nach Ihrem Tod, sobald eine Sterbeurkunde und eine Vollmacht des Testamentsvollstreckers vorliegen.',
      answer11:
        'Nein. Wir weisen dies ausdrücklich darauf hin und bieten sogar Unterstützung an, damit Sie als Kunde KEINE Passwörter in gut geregelt speichern oder anzeigen. Stattdessen ermutigen wir Sie, die Zugangsdaten für relevante Konten separat zu speichern und an einem sicheren Ort aufzubewahren, zu dem Ihre Familie Zugang hat. Diesen sicheren Ort dokumentieren Sie für Ihre Angehörigen in gut geregelt.',
    },
  },
  situations: {
    'tod-verwandter': {
      content: {
        main: {
          title: 'Digitaler Nachlass',
          description:
            'Der digitale Nachlass regelt, was mit Ihren Online-Konten, sozialen Netzwerken und digitalen Daten nach Ihrem Tod geschieht. Sie bestimmen, was gelöscht, übertragen oder weitergeführt werden soll.',
        },
        columns: [
          {
            title: 'Warum jetzt',
            description: [
              'In der heutigen Zeit hinterlassen wir eine Vielzahl an digitalen Spuren. Indem Sie Ihren digitalen Nachlass regeln, verhindern Sie, dass sensible Daten unkontrolliert im Netz bleiben und entlasten Ihre Angehörigen.',
            ],
          },
          {
            title: 'Ihre Vorteile',
            description: [
              'Kontrolle über Ihre digitalen Daten',
              'Schutz vor Identitätsmissbrauch',
              'Entlastung Ihrer Angehörigen',
            ],
          },
        ],
        ctas: {
          learnMore: 'Mehr erfahren',
          startProcess: 'Prozess starten',
        }
      },
    },

    pensionierung: {
      content: {
        main: {
          title: 'Digitaler Nachlass',
          description:
            'Ihr digitales Leben hinterlässt Spuren – von Online-Accounts bis zu Social Media. Mit einer Regelung für Ihren digitalen Nachlass bestimmen Sie, was nach Ihrem Tod mit Ihren Daten geschieht.',
        },
        columns: [
          {
            title: 'Warum jetzt',
            description: [
              'Im digitalen Zeitalter ist es wichtig, auch den digitalen Nachlass zu regeln, um den Umgang mit sensiblen Daten zu sichern.',
            ],
          },
          {
            title: 'Ihre Vorteile',
            description: [
              'Schutz Ihrer digitalen Identität',
              'Vermeidung von Missbrauch',
              'Entlastung der Angehörigen',
            ],
          },
        ],
        ctas: {
          learnMore: 'Mehr erfahren',
          startProcess: 'Prozess starten',
        }
      },
    },

    unternehmensgruendung: {
      content: {
        main: {
          title: 'Digitaler Nachlass',
          description:
            'Der digitale Nachlass regelt, was mit Ihren Online-Konten, sozialen Netzwerken und digitalen Daten nach Ihrem Tod geschieht. Sie bestimmen, was gelöscht, übertragen oder weitergeführt werden soll.',
        },
        columns: [
          {
            title: 'Warum jetzt',
            description: [
              'In der heutigen Zeit hinterlassen wir eine Vielzahl an digitalen Spuren. Indem Sie Ihren digitalen Nachlass regeln, verhindern Sie, dass sensible Daten unkontrolliert im Netz bleiben und entlasten Ihre Angehörigen.',
            ],
          },
          {
            title: 'Ihre Vorteile',
            description: [
              'Kontrolle über Ihre digitalen Daten',
              'Schutz vor Identitätsmissbrauch',
              'Entlastung Ihrer Angehörigen',
            ],
          },
        ],
        ctas: {
          learnMore: 'Mehr erfahren',
          startProcess: 'Prozess starten',
        }
      },
    },
  },
};

export default digitalFootprintLanding;
